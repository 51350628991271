/* COPYRIGHT SMARTTRACK */

/* IMPORTS */

/* ELEMENTS */

h1,
h2,
h3,
h4,
p,
ul,
li,
table,
tbody,
thead,
th,
tr,
div {
	font-family: 'Barlow', Verdana, Arial, Helvetica, sans-serif;
	color: var(--brand-dark-gray);
}
a,
input,
textarea,
select,
label,
button {
	font-family: 'Barlow', Verdana, Arial, Helvetica, sans-serif;
}
