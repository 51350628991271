/* COPYRIGHT SMARTTRACK */

/* IMPORTS */

/* ELEMENTS */

body,
html {
	overflow-x: hidden;
}
input {
	-webkit-appearance: none;
}

/* GENERAL CONTENT */

.animate {
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
}
.full-width-row {
	width: 98%;
	min-width: 98%;
	margin-left: auto;
	margin-right: auto;
	max-width: initial;
}
.full-width {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: initial;
}
input:focus {
	outline: none;
	border: none !important;
}
button:focus {
	outline: none;
}
.navigation-link {
	font-weight: 500;
	font-size: 16px;
	color: var(--brand-white);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	white-space: nowrap;
}
.footer .navigation-link {
	font-weight: 500;
	font-size: 14px;
	color: var(--brand-medium-gray);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	white-space: nowrap;
}
.navigation-link:link,
.navigation-link a {
	color: var(--brand-white);
}
.footer .navigation-link:link,
.footer .navigation-link a {
	color: var(--brand-medium-gray);
}
.navigation-link:visited,
.navigation-link a:visited {
	color: var(--brand-white);
}
.footer .navigation-link:visited,
.footer .navigation-link a:visited {
	color: var(--brand-medium-gray);
}
.navigation-link:hover,
.navigation-link a:hover {
	color: var(--brand-white);
	opacity: 0.9;
}
.footer .navigation-link:hover,
.footer .navigation-link a:hover {
	color: var(--brand-medium-gray);
	opacity: 0.55;
}
.blue-background {
	background-color: var(--brand-primary-dark);
}
.preload * {
	transition: none !important;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}
.grecaptcha-badge {
	z-index: 99;
}
.hide-captcha .grecaptcha-badge {
	visibility: hidden;
}
.align-left {
	text-align: left;
}
.align-center {
	text-align: center;
}
.align-right {
	text-align: right;
}
.flex-left {
	flex-grow: 1;
}
.link-underline a,
.link-underline a:link,
.link-underline a:visited {
	color: inherit;
	text-decoration: underline;
}

/* HEADER CONTENT */

.header {
	padding: 20px 0px 20px 15px;
	background-color: var(--brand-primary-dark);
	width: 100%;
	z-index: 999;
	position: absolute;
}
.header.dashboard {
	background-color: var(--brand-nav-bg);
}
.expanded-header {
	background-color: var(--brand-primary-dark);
	display: none;
	position: absolute;
	left: 0;
	width: 100%;
}
.expanded-header.expanded {
	background-color: var(--brand-primary-dark);
	display: block;
}
.header .logo {
	width: 170px;
}
.header .logo.keeler {
	position: absolute;
	top: 0px;
	width: 76px;
}
.header-top-bar-component {
	position: relative;
	z-index: 9;
}
.header-offset-inner {
	background-color: var(--brand-primary-dark);
}
.header-offset-inner.dashboard {
	background-color: var(--brand-nav-bg);
}
.menu-link a {
	font-weight: 700;
	font-size: 14.5px;
	color: var(--brand-white);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.menu-link a * {
	color: var(--brand-white);
}
.menu-link a img {
	width: 18px;
	height: 18px;
	margin: 7px 0px 0px 8px;
	float: right;
}
.header .logo-container {
	margin: auto auto;
	margin-top: 2px;
	text-align: left;
	cursor: pointer;
}
.header button {
	font-weight: 600;
	font-size: 16px;
	height: 48px;
	margin-top: 0px;
	cursor: pointer;
	border-radius: 3px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.navigation-shadow-button {
	width: 35px;
	height: 35px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: 0px 3px 15px rgb(102 74 74 / 8%);
	background-color: var(--brand-white);
	border-radius: 50%;
	margin: -6px 0px 0px 20px;
}
.navigation-shadow-button.cart {
	background-image: url('/img/icons/cart.png');
	background-size: 50% 50%;
}
.button-solid {
	background-color: var(--brand-white);
	color: var(--brand-button-gray);
	width: 103px;
}
.button-solid-white {
	background-color: var(--brand-white);
	color: var(--brand-primary);
	width: 193px;
}
.create-account-button {
	background-color: var(--brand-yellow);
	color: var(--brand-button-yellow-gray);
	width: 155px;
}
.button-outline {
	background-color: none;
	color: var(--brand-white);
	border: 2px solid var(--brand-white);
	width: 83px;
}
.button-outline-white {
	background-color: none;
	color: var(--brand-white);
	width: 83px;
}
.button-solid:hover {
	opacity: 0.9;
}
.button-outline:hover {
	background-color: var(--brand-white);
	color: var(--brand-primary);
	opacity: 0.9;
}
.button-solid:active {
	opacity: 1;
}
.button-outline:active {
	opacity: 1;
}
.nav-container {
	margin-right: 3px;
	float: right;
}
.nav-item-container {
	text-align: center;
	float: right;
	margin-top: 12px;
	cursor: pointer;
}
.expanded-nav-item-container {
	text-align: center;
	cursor: pointer;
	padding: 10px 0px 10px 0px;
}
.nav-item-container h3 {
	color: var(--brand-white);
	margin: 11px 0px 0px 12px;
	font-size: 16px;
	font-weight: 500;
}
.dashboard .nav-item-container h3 {
	color: var(--brand-dark-gray);
}
.nav-item-container .menu-arrow {
	width: 16px;
	height: 16px;
	margin-left: 12px;
	margin-top: 3px;
	background-image: url('/img/icons/menu-arrow.png');
	background-position: center;
	background-size: cover;
}
.nav-item-container .menu-arrow.open {
	background-image: url('/img/icons/menu-arrow-open.png');
}
.dashboard .nav-item-container .menu-arrow {
	background-image: url('/img/icons/action-arrow.png');
	background-position: center;
	background-size: cover;
}
.dashboard .nav-item-container .menu-arrow.open {
	background-image: url('/img/icons/action-arrow-open.png');
}
.header .profile-image-icon {
	border-radius: 50%;
	height: 45px;
	width: 45px;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: var(--brand-white);
	box-shadow: 0px 3px 15px rgba(102, 74, 74, 0.08);
}
.header .profile-image-icon h3 {
	font-size: 1.5rem;
	color: var(--brand-primary);
	font-weight: 700;
	padding-top: 5px;
	margin: 0px;
}
.content-section .profile-image-icon {
	border-radius: 50%;
	height: 80px;
	width: 80px;
	margin: 0px auto;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.profile-image-icon .profile-image-icon h3 {
	font-size: 3rem;
	color: var(--brand-primary);
	font-weight: 600;
	padding-top: 2px;
	margin: 0px;
}
.dropdown {
	position: absolute;
	background-color: var(--brand-white);
	min-width: 290px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	z-index: 99 !important;
	right: 2.3vw;
	top: 78px;
	display: none;
	border-radius: 4px;
	overflow: scroll;
	max-height: 80vh;
}
.dropdown .account-container {
	padding: 20px 15px 20px 15px;
	text-align: center;
	background-color: var(--brand-nav-bg);
}
.dropdown .account-container a,
.dropdown .account-container a:link,
.dropdown .account-container a:visited,
.dropdown .account-container a:hover {
	background-color: var(--brand-nav-bg) !important;
	padding: 0px;
}
.dropdown .account-container h3 {
	font-weight: 700;
	font-size: 1.15rem;
	margin: 0px 0px 2px 0px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.dropdown .account-container p {
	font-size: 0.8rem;
	margin: 0px;
	color: var(--brand-field-gray);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.dropdown .account-container .profile-image-icon {
	margin: 0px auto 12px;
	width: 60px;
	height: 60px;
}
.dropdown .account-container .profile-image-icon h3 {
	font-size: 2.15rem;
}
.action-dropdown {
	position: absolute;
	background-color: var(--brand-white);
	width: 300px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	z-index: 99 !important;
	border-radius: 4px;
	top: 50px;
	overflow: hidden;
	display: none;
}
.dropdown.show,
.action-dropdown.show {
	display: block;
}
.dropdown a,
.action-dropdown a {
	padding: 18px 18px 18px 25px;
	display: block;
	color: var(--brand-dark-gray);
	background-color: var(--brand-white);
	font-size: 15px;
	font-weight: 500;
}
.dropdown a {
	font-size: 13px;
	padding: 14px 18px 14px 25px;
}
.dropdown a:link,
.dropdown a:visited,
.left-nav-bar a:link,
.left-nav-bar a:visited,
.action-dropdown a:link,
.action-dropdown a:visited {
	color: var(--brand-dark-gray);
}
.dropdown a:hover,
.left-nav-bar a:hover,
.link-active,
.action-dropdown a:hover {
	background-color: var(--brand-dropdown-bg) !important;
}
.action-sheet-button {
	width: 100px !important;
	float: left !important;
	margin-top: 2px !important;
	padding: 10px 0px 10px 0px !important;
}
.action-arrow {
	width: 14px !important;
	margin-left: 4px !important;
	margin-bottom: 1px;
}
.sort-arrow {
	width: 16px !important;
	float: right;
	margin-top: 5px;
}
.nav-icon {
	width: 18px;
	height: 18px;
	margin-right: 7px;
	margin-bottom: 2px;
	border: none;
	border-color: transparent;
}
.dropdown .nav-icon {
	width: 16px;
	height: 16px;
}
.logout-link {
	color: var(--brand-primary) !important;
}
.left-nav-bar {
	padding: 0px;
	height: 100%;
	position: fixed;
	background-color: var(--brand-nav-bg);
	overflow: hidden;
}
.left-nav-bar a {
	padding: 23px 0px 23px 25px;
	display: block;
	color: var(--brand-nav-bar-text);
	background-color: var(--brand-nav-bg);
	margin-right: -2px;
	font-size: 16px;
	font-weight: 500;
	border-top-right-radius: 35px;
	border-bottom-right-radius: 35px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
}
.left-nav-content {
	background: var(--brand-nav-bg);
}
#left-nav-bar-content {
	background-color: var(--brand-nav-bg);
}
a[name='left-nav-item'] {
	width: 100%;
}

/* FOOTER CONTENT */

.footer {
	background-color: var(--brand-white);
	width: 100%;
	position: relative;
	z-index: 1;
}
.footer .download-section {
	background-color: var(--brand-lighter-gray);
	box-shadow: inset 0px 2px 5px -2px rgba(0, 0, 0, 0.24);
	padding: 35px 15px 35px 15px;
	text-align: center;
}
.footer .social-section {
	background-color: var(--brand-lighter-gray);
	box-shadow: inset 0px 2px 5px -2px rgba(0, 0, 0, 0.24);
	padding: 30px 15px 30px 15px;
	text-align: center;
}
.footer .navigation-section {
	background-color: var(--brand-light-gray);
	box-shadow: inset 0px 2px 5px -2px rgba(0, 0, 0, 0.24);
	text-align: center;
	padding: 23px 10px 24px 10px;
}
.footer-divider {
	margin-bottom: 9px;
}
.footer-item {
	margin: 0px 18px 0px 18px;
}
.copyright-label {
	font-weight: 500;
	font-size: 13.2px;
	color: var(--brand-medium-gray);
}
.footer .social-section .show-for-custom-medium-up-2 h2,
.footer .download-section .show-for-custom-medium-up-2 h2 {
	font-weight: 300;
	font-size: 36px;
	color: var(--brand-medium-gray);
	margin-bottom: 0px;
	display: inline-block;
	margin-right: 14px;
	vertical-align: middle;
}
.footer .social-section .hide-for-custom-medium-up-2 h2,
.footer .download-section .hide-for-custom-medium-up-2 h2 {
	font-weight: 300;
	font-size: 28px;
	color: var(--brand-medium-gray);
	margin-bottom: 0px;
	display: inline-block;
	vertical-align: middle;
}
.footer .social-section .medium-up-icons {
	display: inline-block;
	margin-left: 9px;
	margin-bottom: 1px;
	width: 65px;
	height: 65px;
}
.footer .social-section .small-icons {
	display: inline-block;
	margin: 6px 4px 6px 4px;
	width: 43px;
	height: 43px;
}
.footer .download-section .medium-up-icons {
	display: inline-block;
	margin-left: 9px;
	margin-bottom: 1px;
	width: auto;
	height: 60px;
	border-radius: 8px;
}
.footer .download-section .small-icons {
	display: inline-block;
	margin: 6px 4px 6px 4px;
	width: auto;
	height: 43px;
	border-radius: 8px;
}
.footer .footer-navigation-section {
	margin-bottom: 26px;
}

/* BLOG CONTENT */

.hero-section-blog {
	padding: 55px 0px 70px 0px;
	margin-left: auto;
	margin-right: auto;
	background-color: var(--brand-primary-dark);
}
.hero-title-section-blog {
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
}
.hero-title-section-blog h1 {
	font-size: 2.3rem;
	line-height: 1.05;
	color: var(--brand-dark-gray);
	font-weight: bold;
}
.hero-title-section-blog h2 {
	font-size: 1.3rem;
	font-weight: 400;
}
.hero-title-section-blog h3 {
	color: var(--brand-dark-gray);
	font-weight: 400;
	font-size: 1.7rem;
}
.hero-title-section-blog h4 {
	font-size: 0.68rem;
	font-weight: 700;
	color: var(--brand-dark-gray);
}
.hero-title-section-blog .logo {
	width: 40px;
}
.hero-title-section-blog button {
	color: var(--brand-white);
	background-color: none;
	height: 36px;
	border: 2px solid var(--brand-white);
	border-radius: 3px;
	font-weight: 700;
	font-size: 0.72rem;
	padding-bottom: 1px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
	cursor: pointer;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.hero-title-section-blog button:focus {
	outline: none;
	color: var(--brand-white);
	background-color: none;
	border: 2px solid var(--brand-white);
}
.hero-title-section-blog button:hover {
	background-color: var(--brand-white);
	color: var(--brand-primary);
}
.hero-title-section-blog button:active {
	opacity: 1;
}
.posts-collection {
	line-height: 2em;
	background: var(--brand-white);
	display: block;
	margin: 0px auto 0px;
}
.posts-collection-parent {
	box-shadow: inset 0px 2px 5px -2px rgba(0, 0, 0, 0.24);
	padding-top: 45px;
	background-color: var(--brand-white);
}
.posts-collection-parent p {
	line-height: 1.9;
}
#postsCollectionView a:hover {
	text-decoration: none !important;
}
.hero-section-blog h1 {
	line-height: 1.35 !important;
}
@media only screen {
	.posts-collection {
		/* Small screens */
		width: 90%;
	}
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
	.posts-collection {
		width: 500px;
	}
}
@media screen and (min-width: 64em) {
	/* Large and up screens */
	.posts-collection {
		width: 550px;
	}
}
.posts-collection button {
	color: var(--brand-primary);
	background-color: none;
	height: 36px;
	border: 2px solid var(--brand-primary);
	border-radius: 3px;
	font-weight: 700;
	font-size: 0.72rem;
	padding-bottom: 1px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
	cursor: pointer;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.posts-collection button:focus {
	outline: none;
	color: var(--brand-primary);
	background-color: none;
	border: 2px solid var(--brand-primary);
}
.posts-collection button:hover {
	background-color: var(--brand-primary);
	color: var(--brand-white);
	opacity: 0.8;
}
.posts-collection button:active {
	opacity: 1;
}
.posts-collection h2 {
	color: var(--brand-dark-gray);
	font-size: 1.8rem;
	margin-top: 2px;
	font-weight: bold;
}
.posts-collection h4 {
	color: var(--brand-dark-gray);
	font-size: 13px;
}
.posts-collection span {
	color: var(--brand-dark-gray);
	font-size: 13px;
}
.story-content h4 {
	color: var(--brand-dark-gray);
	font-size: 14px;
	font-weight: 700;
}
.story-content span {
	color: var(--brand-dark-gray);
	font-size: 14px;
	font-weight: 500;
}
.posts-collection a:link {
	color: var(--brand-primary);
}
.posts-collection a:visited {
	color: var(--brand-primary);
}
.posts-collection a:hover {
	text-decoration: underline;
}

/* GENERAL CONTENT */

.hero-section {
	padding: 55px 0px 55px 0px;
	margin-left: auto;
	margin-right: auto;
	background-color: var(--brand-primary-dark);
}
.hero-title-section {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
.side-image-text-holder {
	padding: 130px 0px 150px 0px;
	background-color: var(--brand-white-gray);
}
.side-image-text-holder h1 {
	line-height: 1.15;
	color: var(--brand-dark-gray);
	font-weight: bold;
}
.side-image-text-holder h2 {
	line-height: 1.6;
	color: var(--brand-dark-gray);
	font-weight: 400;
}
.side-image-text-section {
	margin: 0px auto;
	text-align: center;
}
.side-image-text-left {
	text-align: right;
	padding: 0px 0px 0px 0px;
	float: left;
}
.side-image-text-right {
	padding: 0px 10px 0px 25px;
	text-align: center;
	float: right;
}
.split-form-section,
.split-form-inner,
.split-form-inner-wide,
.split-form-text {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
.hero-title-section h1,
.split-form-section h1 {
	font-size: 2.2rem;
	line-height: 1.2;
	color: var(--brand-dark-gray);
	font-weight: 700;
}
.split-form-section p {
	margin-top: 20px;
	font-size: 13px;
	font-weight: 500;
	color: var(--brand-text-light-gray);
}
.split-form-section a:link {
	color: var(--brand-primary);
}
.split-form-section a:visited {
	color: var(--brand-primary);
}
.split-form-section a:hover {
	text-decoration: underline;
}
.hero-title-section h2 {
	font-size: 1.15rem;
	line-height: 1.6;
	color: var(--brand-white);
	font-weight: 500;
}
.split-form-section h2 {
	font-size: 1.08rem;
	line-height: 1.6;
	color: var(--brand-dark-gray);
	font-weight: 400;
}
.hero-title-section .logo {
	width: 65px;
}
.career-card {
	background-color: var(--brand-primary);
	padding: 0px 0px 80px;
}
.career-inner-card {
	padding: 20px 30px 10px 30px;
	background-color: var(--brand-white);
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.career-card h4 {
	font-weight: bold;
	font-size: 1.55rem;
	color: var(--brand-dark-gray);
}
.career-card a {
	color: var(--brand-primary);
	background-color: none;
	height: 36px;
	border: 2px solid var(--brand-primary);
	border-radius: 3px;
	font-weight: 700;
	font-size: 12.9px;
	padding: 10px 16px !important;
	cursor: pointer;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.career-card a:hover {
	background-color: var(--brand-primary);
	color: var(--brand-white);
	opacity: 0.8;
}
.text-card {
	box-shadow: inset 0px 2px 5px -2px rgba(0, 0, 0, 0.24);
	background-color: var(--brand-white);
	padding: 40px 0px 60px;
}
.text-card p,
.text-card ul,
.text-card li {
	color: var(--brand-dark-gray);
	line-height: 1.92rem;
	font-size: 15.5px;
}
.text-card a:link,
.side-image-text-holder a:link {
	color: var(--brand-primary);
	font-weight: 500;
}
.text-card a:visited,
.side-image-text-holder a:visited {
	color: var(--brand-primary);
}
.text-card a:hover,
.side-image-text-holder a:hover {
	text-decoration: underline;
}
.text-card h2 {
	color: var(--brand-dark-gray);
	font-size: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
}
.text-card h3 {
	color: var(--brand-dark-gray);
	font-size: 1.2rem;
	font-weight: 700;
}
.contact-holder {
	margin: 35px auto 15px;
}
.split-form-right {
	background-color: var(--brand-white-gray);
	padding: 90px 0px 165px;
}
.split-form-right-2 {
	background-color: var(--brand-white-gray);
	padding: 80px 0px 102px;
}
.split-form-left {
	background-color: var(--brand-primary-dark);
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	padding: 137px 20px 150px;
}
.split-form-left-2 {
	background-color: var(--brand-primary-dark);
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	padding: 137px 20px 167px;
}
.split-form-left-3 {
	width: 10px;
}
.bg-image-row {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0% 0%;
}
.bg-image-row-2 {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0% 0%;
}
.split-form-center {
	background-color: var(--brand-white-gray);
	padding: 110px 0px 150px;
	min-height: 615px;
}
.split-form-left h2,
.split-form-left-2 h2 {
	width: 350px;
	margin: 23px auto 0px;
	font-size: 1.28rem;
	line-height: 1.6;
	font-weight: 500;
	color: var(--brand-white);
}
.split-form-left h3,
.split-form-left-2 h3 {
	margin: 17px auto 4px;
	font-size: 0.9rem;
	font-weight: 500;
	color: var(--brand-white);
}
.split-form-left h4,
.split-form-left-2 h4 {
	font-size: 0.7rem;
	color: var(--brand-white);
}
@media only screen {
	.contact-holder {
		/* Small screens */
		width: 100%;
	}
}
@media only screen and (min-width: 40.063em) {
	/* Medium and up screens */
	.contact-holder {
		width: 86%;
	}
}
@media only screen {
	.split-form-right,
	.split-form-right-2 {
		width: 100%;
	}
	.split-form-left,
	.split-form-left-2,
	.split-form-left-3 {
		display: none;
	}
}
@media only screen and (min-width: 56.563em) {
	/* Medium and up screens */
	.split-form-right,
	.split-form-right-2 {
		width: 58.33333%;
	}
	.split-form-left,
	.split-form-left-2,
	.split-form-left-3 {
		display: block;
		width: 41.66667%;
	}
}
.testimonial-image {
	width: 117px;
	height: 117px;
	border-radius: 50%;
}
.block-alert {
	margin: 0px auto;
	overflow: hidden;
	text-align: center;
	background-color: var(--brand-white);
	border-radius: 4px;
	line-height: 1.4;
	font-weight: 500;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.block-alert p {
	margin: 0px;
	padding: 14px 16px 14px 16px;
	color: var(--brand-orange);
}
.colored-row {
	background-color: var(--brand-primary-dark);
}
.blue-skew-section {
	background-color: var(--brand-primary-dark);
	color: var(--brand-white-text);
	position: relative;
	z-index: 1;
}
.blue-skew-section:before {
	content: '';
	background: var(--brand-primary-dark);
	height: 140px;
	transform: skewY(-4deg);
	position: absolute;
	left: 0;
	right: 0;
	z-index: -1;
	top: -60px;
}
.blue-skew-section:after {
	content: '';
	background: var(--brand-primary-dark);
	height: 140px;
	transform: skewY(-4deg);
	position: absolute;
	left: 0;
	right: 0;
	z-index: -1;
	bottom: -60px;
}
.white-skew-section {
	background-color: var(--brand-white);
	position: relative;
	z-index: 1;
}
.white-skew-section:before {
	content: '';
	background: var(--brand-white);
	height: 140px;
	transform: skewY(-4deg);
	position: absolute;
	left: 0;
	right: 0;
	z-index: -1;
	top: -60px;
}
.white-skew-section:after {
	content: '';
	background: var(--brand-white);
	height: 140px;
	transform: skewY(-4deg);
	position: absolute;
	left: 0;
	right: 0;
	z-index: -1;
	bottom: -60px;
}
.light-grey-section {
	background-color: var(--brand-white-gray);
}
.role-image {
	height: 130px;
	width: 130px;
	border-radius: 50%;
	margin: auto;
	background-color: var(--brand-white);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
#standard-role-image {
	background-image: url('/img/icons/standard-role.png');
	background-position: center;
	background-size: cover;
}
#leader-role-image {
	background-image: url('/img/icons/leader-role.png');
	background-position: center;
	background-size: cover;
}
#super-role-image {
	background-image: url('/img/icons/super-role.png');
	background-position: center;
	background-size: cover;
}
#affordable-icon-image {
	background-image: url('/img/icons/affordable-icon.png');
	background-position: center;
	background-size: cover;
}
#fast-icon-image {
	background-image: url('/img/icons/fast-icon.png');
	background-position: center;
	background-size: cover;
}
#simple-icon-image {
	background-image: url('/img/icons/simple-icon.png');
	background-position: center;
	background-size: cover;
}
.enroll-card {
	cursor: pointer;
	margin-top: 30px;
}
.enroll-card h3 {
	text-align: center;
	font-weight: 700;
	font-size: 1.45rem;
	margin: 23px 0px 0px 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.enroll-card p {
	text-align: center;
	font-weight: 500;
	font-size: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
p.role-payment-detail {
	white-space: normal;
	overflow: visible;
	text-overflow: unset;
	padding: 0px 15px;
	font-weight: 700;
	margin-top: -5px;
}
.role-detail {
	height: 0px;
	overflow: hidden;
	width: 94%;
	margin: 20px auto 0px;
}
.role-detail li {
	font-weight: 400;
	font-size: 1rem;
	color: var(--brand-dark-gray);
}
.find-out-more {
	position: relative;
	top: -139px;
	cursor: pointer;
}
.find-out-more h3 {
	text-align: center;
	font-weight: 600;
	font-size: 0.8rem;
}
.cta-section h1 {
	color: var(--brand-white);
	font-weight: 700;
	font-size: 2.5rem;
	line-height: 1.25;
}
.cta-section h2 {
	color: var(--brand-white);
	font-weight: 700;
	font-size: 1.6rem;
}
.cta-section h3 {
	color: var(--brand-white);
	font-size: 1.25rem;
	margin-top: 25px;
	font-weight: 500;
}
.cta-button {
	height: 48px;
	font-weight: 600 !important;
	text-align: center;
	font-size: 1em;
	line-height: 1;
	border-radius: 4px;
	border: none;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	cursor: pointer;
}
.yellow-cta {
	background-color: var(--brand-yellow);
	color: var(--brand-button-yellow-gray);
	width: 175px;
	margin-right: 10px;
	margin-top: 15px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.white-cta {
	background-color: var(--brand-white);
	color: var(--brand-button-gray);
	width: 140px;
	margin-top: 12px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.yellow-cta:hover,
.yellow-cta:disabled,
.yellow-cta:focus {
	background-color: var(--brand-light-yellow);
	color: var(--brand-button-yellow-gray);
}
.white-cta:hover,
.white-cta:disabled,
.white-cta:focus {
	background-color: var(--brand-white);
	color: var(--brand-button-gray);
}
.faq-divider {
	width: 50px;
	height: 7px;
	background-color: var(--brand-primary);
	margin-bottom: 30px;
	border-radius: 4px;
}
.faq-content h2 {
	font-weight: 700;
	margin-top: 65px;
	color: var(--brand-dark-gray);
	font-size: 2rem;
}
.faq-content h3 {
	font-weight: 600;
	margin-top: 35px;
	color: var(--brand-dark-gray);
	font-size: 1.7rem;
}
.faq-content p {
	color: var(--brand-dark-gray);
	line-height: 1.8rem;
	font-size: 15.8px;
}
.dashboard-cta {
	background-color: var(--brand-white);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	padding: 20px;
	border-radius: 6px;
	margin: -5px 0px -10px;
}
.dashboard-cta.top {
	margin-top: 40px;
}
.dashboard-cta h4 {
	text-align: center;
}
.dashboard-cta button {
	display: block;
	height: 57px;
	max-width: 300px;
	width: 90%;
	margin: 12px auto 0px;
}
.modal {
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
.modal-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--brand-white);
	width: 90%;
	margin: 0px auto;
	max-width: 385px;
	border-radius: 8px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.22);
	overflow: hidden;
}
.modal-inner.medium {
	max-width: 410px;
}
.modal-inner.large {
	max-width: 510px;
}
.modal-inner.xlarge {
	max-width: 640px;
}
.modal-inner .page-button {
	width: 100%;
	box-shadow: none;
	border-radius: 0px;
	font-size: 17px;
	margin-top: 0px;
}
.modal-inner .left-button {
	width: 50%;
	display: inline-block;
}
.modal-inner .right-button {
	width: 50%;
	float: right;
}
.modal-inner .grey-button {
	background-color: var(--brand-medium-light-gray);
}
.page-button.grey-button:hover,
.page-button.grey-button:disabled,
.page-button.grey-button:focus {
	background-color: var(--brand-medium-light-gray);
	color: var(--brand-white);
	opacity: 0.8;
}
.modal-content {
	padding: 11px 20px 25px 20px;
}
.modal-content h1 {
	font-weight: 600;
	font-size: 1.9rem;
	text-align: center;
	margin-top: 5px;
	line-height: 1.3;
}
.modal-input-container {
	padding-left: 20px;
	padding-right: 20px;
}
.modal-input-container.form {
	margin-top: -20px !important;
	background-color: var(--brand-white) !important;
}
.modal-input-container.form input:hover,
.modal-input-container.form input:active,
.modal-input-container.form input:focus {
	border: none !important;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14) !important;
	color: var(--brand-dark-gray) !important;
	outline: 0 !important;
}
.modal-input-container.form select {
	padding: 0px 18px 0px;
	text-align: left;
}
.modal-inner h3 {
	color: var(--brand-primary);
	font-weight: 700;
	text-align: center;
	font-size: 0.8rem;
	margin: 18px 0px 16px;
}
.modal-inner input {
	width: 90%;
	height: 60px;
	border: 1px solid var(--brand-placeholder-gray);
	margin: -20px auto 25px;
	padding-left: 18px;
	padding-right: 18px;
	box-shadow: none;
	border-radius: 5px;
	text-align: left;
	color: var(--brand-dark-gray);
	outline: 0;
}
.modal-inner input:hover,
.modal-inner input:active,
.modal-inner input:focus {
	border: 1px solid var(--brand-placeholder-gray) !important;
	box-shadow: none !important;
	color: var(--brand-dark-gray) !important;
	outline: 0 !important;
}
.modal-inner ::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: var(--brand-placeholder-gray) !important;
}
.modal-inner :-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--brand-placeholder-gray) !important;
	opacity: 1;
}
.modal-inner ::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--brand-placeholder-gray) !important;
	opacity: 1;
}
.modal-inner :-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--brand-placeholder-gray) !important;
}
.modal-content p {
	text-align: center;
}
.modal-inner .divider-line {
	height: 1px;
	background-color: gray;
	opacity: 0.2;
}
.show-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.error-content-text {
	margin: 20px 0px 16px 0px;
}
.modal-date-range-container {
	margin: 0px 0px 30px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 0px 20px 0px 20px;
}
.modal-date-range-container input {
	margin: 0px;
}
.modal-date-range-seperator {
	width: 30px;
	height: 2px;
	flex-shrink: 0;
	background-color: var(--brand-placeholder-gray);
}

/* INPUT CONTENT */

.application-view {
	position: relative;
}
.application-view input,
.application-view select {
	text-align: left;
	height: 48px;
	border: none;
	background-color: var(--brand-white-bg);
	color: var(--brand-dark-gray);
	border-radius: 4px;
	padding-left: 16px;
	margin-bottom: 0px;
	outline: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	box-shadow: none !important;
}
.application-view select:required:invalid {
	color: var(--brand-placeholder-gray);
	outline: 0;
	border: none;
	box-shadow: none !important;
}
.application-view select::-ms-expand {
	display: none;
	border: none;
	box-shadow: none !important;
}
.application-view input:focus,
.application-view input:active {
	background-color: var(--brand-white-bg);
	outline: 0;
	transform: none !important;
	border: none;
}
.application-view input[data-empty='false'],
.application-view input:valid,
.application-view input:focus,
.application-view input:-webkit-autofill {
	padding: 8px 33px 8px 16px;
	outline: 0;
	box-shadow: none !important;
	transform: none !important;
	border: none;
}
.application-view ::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: var(--brand-placeholder-gray) !important;
}
.application-view :-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--brand-placeholder-gray) !important;
	opacity: 1;
}
.application-view ::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--brand-placeholder-gray) !important;
	opacity: 1;
}
.application-view :-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--brand-placeholder-gray) !important;
}
.define-select-element,
.define-select-element:focus,
.define-select-element:active,
.define-select-element:hover {
	text-align: left;
	height: 48px;
	outline: none;
	border: 1px solid #8a8a8a;
	background-color: var(--brand-white);
	color: var(--brand-dark-gray);
	border-radius: 4px;
	padding-left: 16px;
	-moz-appearance: none;
	-webkit-appearance: none;
	box-shadow: none !important;
	width: 80%;
	margin-bottom: 15px;
}
.form-section fieldset,
.content-section fieldset {
	margin: 0;
	padding: 0;
	border: 0;
	position: relative;
}
.form-holder fieldset .intl-tel-input {
	width: 100%;
}
.form-fieldset {
	margin: 15px auto 0px !important;
	width: 94%;
}
.form-fieldset label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.form-actions-holder {
	width: 90%;
	height: 57px;
	margin: 10px auto;
	text-align: center;
}
.form-section input,
.content-section input,
.content-section textarea,
.form-holder select {
	font-weight: 500 !important;
	text-align: center;
	font-size: 1.15em;
	line-height: 1;
	color: var(--brand-dark-gray);
	display: block;
	height: 67px;
	padding: 25px 16px 20px 16px;
	border-color: var(--brand-white);
	outline: 0;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;
	border: none;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	width: 100%;
	margin: 0 0 1rem;
}
.form-holder.left select {
	text-align: left;
}
.form-holder input,
.form-holder textarea {
	text-align: left;
	padding-left: 18px !important;
	padding-right: 18px !important;
}
.form-holder textarea {
	padding-top: 32px !important;
}
.form-holder select {
	padding: 26px 18px 10px;
	margin: 0px;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: url('/img/icons/filter-arrow.png');
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position-x: 96%;
	background-position-y: center;
	cursor: pointer;
	background-color: var(--brand-white);
}
.form-holder select::-ms-expand {
	display: none;
}
.form-holder select:required:invalid {
	color: var(--brand-medium-light-gray);
	outline: 0;
}
.form-section textarea {
	font-weight: 500 !important;
	text-align: center;
	font-size: 1.15em;
	line-height: 1.4;
	color: var(--brand-dark-gray);
	display: block;
	padding-top: 12px;
	padding-left: 16px;
	padding-right: 16px;
	border-color: var(--brand-white);
	outline: 0;
	box-shadow: none !important;
	transition: all 0.2s ease-in-out;
	border-radius: 3px;
	margin-bottom: 14px;
	border: 1.3px solid var(--brand-light-gray);
}
.form-section input:focus,
.form-section input:active,
.form-section textarea:focus,
.form-section textarea:active,
.content-section input:focus,
.content-section input:active,
.content-section textarea:focus,
.content-section textarea:active,
.form-holder select:focus,
.form-holder select:active {
	background-color: var(--brand-white);
	outline: 0;
}
.form-section input[data-empty='false'],
.form-section input:valid,
.form-section input:focus,
.form-section input:-webkit-autofill,
.content-section input[data-empty='false'],
.content-section input:valid,
.content-section input:focus,
.content-section input:disabled,
.content-section input:-webkit-autofill,
.content-section textarea[data-empty='false'],
.content-section textarea:valid,
.content-section textarea:focus,
.content-section textarea:disabled {
	outline: 0;
	padding: 25px 16px 10px;
}
.form-holder input[data-empty='false'],
.form-holder input:valid,
.form-holder input:focus,
.form-holder input:-webkit-autofill {
	outline: 0;
	padding: 25px 18px 10px;
}
.form-section input:focus,
.content-section input:focus,
.content-section textarea:focus {
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.form-section textarea[data-empty='false'],
.form-section textarea:valid,
.form-section textarea:focus {
	outline: 0;
	padding: 26px 16px 10px;
}
.form-section input[data-empty='false'] + label,
.form-section input:valid + label,
.form-section input:focus + label,
.form-section input:-webkit-autofill + label,
.form-section textarea[data-empty='false'] + label,
.form-section textarea:valid + label,
.form-section textarea:focus + label,
.content-section input[data-empty='false'] + label,
.content-section input:valid + label,
.content-section input:focus + label,
.content-section input:disabled + label,
.content-section input:-webkit-autofill + label,
.content-section textarea[data-empty='false'] + label,
.content-section textarea:valid + label,
.content-section textarea:focus + label,
.content-section textarea:disabled + label {
	font-weight: 700 !important;
	font-size: 11.5px;
	left: 0;
	right: 0;
	margin: auto;
	transform: translate3d(0, -8px, 0);
	-webkit-transform: translate3d(0, -8px, 0);
}
.form-holder .profile-image-icon {
	border-radius: 50%;
	height: 90px;
	width: 90px;
	margin: 0px;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	background-color: var(--brand-white);
}
.form-holder .profile-image-icon h3 {
	font-size: 3.3rem;
	color: var(--brand-primary);
	font-weight: 700;
	padding-top: 7px;
}
#assetTypeLabel,
#personTypeLabel,
#userRoleLabel,
#userOrgLabel,
#assetOrgLabel,
#personOrgLabel,
#organizationTerritoryLabel {
	font-weight: 700 !important;
	font-size: 11.5px;
	left: 0;
	right: 0;
	margin: auto;
	transform: translate3d(0, -8px, 0);
	-webkit-transform: translate3d(0, -8px, 0);
}
.activated-label {
	font-weight: 700 !important;
	font-size: 11.5px !important;
	left: 0 !important;
	right: 0 !important;
	margin: auto !important;
	transform: translate3d(0, -8px, 0) !important;
	-webkit-transform: translate3d(0, -8px, 0) !important;
}
.content-section input:disabled {
	background-color: var(--brand-white);
}
.form-section label,
.content-section label {
	position: absolute;
	text-align: center;
	font-weight: 500;
	top: 16px;
	color: var(--brand-medium-light-gray);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	cursor: text;
	font-size: 1.15em;
	left: 0;
	right: 0;
	margin: auto;
}
.form-holder label {
	text-align: left;
	padding-left: 18px;
}
.form-section button {
	color: var(--brand-white);
	background-color: var(--brand-primary);
	height: 50px;
	width: 260px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	border: 2px solid var(--brand-primary);
	border-radius: 4px;
	font-weight: 600;
	font-size: 1.05rem;
	padding-bottom: 1px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
	cursor: pointer;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.form-section button:focus {
	outline: none;
	color: var(--brand-primary);
	background-color: var(--brand-primary);
	border: 2px solid var(--brand-primary);
}
.form-section button:hover,
.form-section button:disabled,
.page-button:hover,
.page-button:disabled,
.page-button:focus {
	background-color: var(--brand-primary);
	color: var(--brand-white);
	opacity: 0.8;
}
.form-section button:active,
.page-button:active {
	opacity: 1;
}
.errorLabel {
	color: var(--brand-primary) !important;
}
#small-button {
	height: 33px !important;
	font-size: 11px;
}
.form-section button span,
.form-holder button span {
	top: -1px;
	position: relative;
}
#classlink-button-text {
	top: 2px;
}
.white-button {
	background-color: var(--brand-white) !important;
	border: 2px solid white !important;
	color: var(--brand-primary) !important;
}
.button-icon {
	width: 22px;
	height: 22px;
	margin-right: 6px;
	position: relative;
	top: 1px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* Hide number field spinners */
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}
.incr-decr-holder {
	position: absolute;
	height: 30px;
	width: 80px;
	background-color: var(--brand-light-gray);
	z-index: 9;
	top: 18px;
	right: 16px;
	border-radius: 6px;
}
.action-holder .incr-decr-holder {
	position: relative;
	top: -1px;
	right: 0px;
	margin-right: 10px;
}
.incr-decr-holder .increment-button {
	height: 100%;
	width: 50%;
	cursor: pointer;
	background-color: transparent;
	border-radius: 6px;
}
.incr-decr-holder .decrement-button {
	height: 100%;
	width: 50%;
	cursor: pointer;
	float: left;
	background-color: transparent;
	border-radius: 6px;
}
.incr-decr-holder .increment-button:hover,
.incr-decr-holder .decrement-button:hover {
	background-color: var(--brand-medium-light-gray);
}
.incr-decr-holder .increment-button i,
.incr-decr-holder .increment-button svg {
	color: var(--brand-medium-gray);
	font-size: 0.9rem;
	background-color: var(--brand-light-gray);
}
.incr-decr-holder .decrement-button i,
.incr-decr-holder .decrement-button svg {
	color: var(--brand-medium-gray);
	margin-top: 2.5px;
	font-size: 0.85rem;
}
input.cart-asset-quantity,
input.cart-asset-quantity:hover,
input.cart-asset-quantity:active,
input.cart-asset-quantity:focus {
	width: 50px;
	height: 30px;
	margin: 0px;
	box-shadow: none;
	padding: 0px !important;
	text-align: center;
	background-color: var(--brand-light-gray);
	border: 1px solid var(--brand-placeholder-gray) !important;
	font-size: 1.03rem;
	position: relative;
	border-radius: 5px;
	top: -1px;
}
.check-in-out-holder,
.asset-activity-holder,
.person-activity-holder,
.health-history-holder {
	background-color: white;
	border-radius: 4px;
	padding: 15px 18px 20px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	margin-bottom: -25px;
}
.check-in-out-holder,
.asset-widget-holder {
	margin-top: 16px;
}
.asset-widget-holder {
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.asset-widget-holder.stock-level {
	background-color: var(--brand-primary);
	border-radius: 4px;
	height: 74px;
	display: flex;
}
.asset-widget-holder.stock-level .stock-level-action {
	width: 30%;
}
.asset-widget-holder.stock-level .stock-level-action button {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.asset-widget-holder.stock-level .stock-level-content {
	flex-grow: 1;
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.asset-widget-holder.stock-level .stock-level-content h4 {
	font-weight: 500;
	color: var(--brand-white);
	margin: 0px;
	font-size: 15px;
}
.asset-widget-holder.stock-level .stock-level-content h3 {
	font-weight: 600;
	color: var(--brand-white);
	margin: 0px;
	font-size: 1.3rem;
}
.widget-minus-icon {
	width: 12px;
	height: 12px;
	background-image: url('/img/icons/minus-icon.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0px auto;
}
.widget-minus-icon.white {
	background-image: url('/img/icons/minus-icon-white.png');
}
.asset-widget-holder.stock-level .stock-level-action .widget-minus-icon {
	width: 16px;
	height: 16px;
	margin-left: 24px;
}
.widget-plus-icon {
	width: 12px;
	height: 12px;
	background-image: url('/img/icons/plus-icon.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0px auto;
}
.widget-plus-icon.white {
	background-image: url('/img/icons/plus-icon-white.png');
}
.asset-widget-holder.stock-level .stock-level-action .widget-plus-icon {
	width: 16px;
	height: 16px;
	margin-right: 24px;
}
.asset-activity-holder,
.person-activity-holder {
	margin-bottom: 25px;
}
.health-history-holder {
	padding: 25px 18px 20px;
}
.check-in-out-holder h4,
.asset-activity-holder h4,
.person-activity-holder h4,
.health-history-holder h4 {
	font-weight: 700 !important;
	font-size: 11.5px;
	color: var(--brand-medium-light-gray);
	text-align: left;
	margin-bottom: 5px;
}
.check-in-out-holder h3,
.asset-activity-holder h3,
.person-activity-holder h3,
.health-history-holder h3 {
	font-weight: 500 !important;
	font-size: 1.15em;
	line-height: 1;
	color: var(--brand-dark-gray);
	text-align: left;
	margin-bottom: 15px;
}
.check-in-out-action,
.widget-action {
	width: 100% !important;
	box-shadow: none !important;
	margin-top: 30px !important;
}
.check-in-out-action {
	margin-top: 0px !important;
}
.widget-action {
	margin-top: 0px !important;
}
.check-in-out-container-view {
	margin-bottom: 26px;
}
.view-asset-activity-button,
.view-health-history-button {
	font-weight: 600;
	cursor: pointer;
	color: var(--brand-medium-light-gray);
	font-size: 14.5px;
	margin-top: 19px;
	text-align: center;
	width: 100%;
}
.view-health-history-button {
	margin-top: 0px;
}
.asset-check-in-out-divider,
.asset-activity-divider,
.person-activity-divider {
	height: 1px;
	width: 100%;
	background-color: var(--brand-light-gray);
	margin-bottom: 10px;
}
#asset-options-menu-holder,
#person-options-menu-holder {
	position: relative;
	display: inline-block;
}
.load-more-button {
	margin-left: 0px;
	font-size: 13px;
	color: var(--brand-medium-gray);
	float: right;
	opacity: 1;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	margin-top: 2px;
}
.load-more-button:hover {
	color: var(--brand-medium-gray) !important;
	opacity: 0.8;
}
#assetactivity-error-holder {
	padding-top: 100px;
	padding-bottom: 100px;
}
#assetactivity-error-holder h4 {
	font-size: 1.1rem;
	color: var(--brand-text-light-gray);
	text-align: center;
}
.gotcha-field {
	display: none !important;
}
.question-input {
	overflow: visible;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0 !important;
	width: 0 !important;
	z-index: -1;
	padding: 1px !important;
}

/* APP CONTENT */

#app-container {
	min-height: 430px;
	overflow: hidden;
	background-color: var(--brand-nav-bg);
}
.application-view,
.content-section {
	padding: 14px 20px 50px 22px;
	background-color: var(--brand-nav-bg);
}
.app-page-content {
	display: none;
	margin-left: -10px;
	margin-bottom: 60px;
	width: 100%;
}
.title-holder {
	display: inline-block;
	width: 100%;
}
.title-holder h1 {
	text-align: left;
	float: left;
	font-size: 1.85rem;
	margin-right: 25px;
	margin-top: 0px;
	margin-bottom: 0px;
	font-weight: 500;
}
.filter-search-holder {
	float: right;
}
.filter-input {
	width: 145px !important;
	background: url('/img/icons/filter-arrow.png');
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position-x: 91%;
	background-position-y: center;
	padding-right: 33px !important;
	cursor: pointer;
	margin-right: 12px !important;
	margin-bottom: 6px !important;
}
.search-input {
	display: inline-block !important;
	width: 235px !important;
	background-color: var(--brand-white-bg) !important;
	background-image: url('/img/icons/search.png') !important;
	background-size: 16px 16px !important;
	background-repeat: no-repeat !important;
	background-position-x: 94% !important;
	background-position-y: center !important;
	padding-right: 33px !important;
	margin-bottom: 6px !important;
}
.collection-content-container {
	padding-top: 10px;
}
.collection-content-container h3,
.metric-card-header h3 {
	margin: 25px 0px 13px;
	font-weight: 600;
	font-size: 0.95rem;
	color: var(--brand-medium-gray);
}
.metric-card-header h3 {
	font-size: 1.1rem;
}
.collection-content-container h3 span,
.metric-card-header h3 span {
	margin-left: 7px;
}
.metric-card-header h3 span {
	font-size: 0.95rem;
	position: relative;
	top: -1px;
	display: inline-block;
}
.collection-content-container .bubble,
.metric-card-header .bubble {
	font-size: 0.88rem;
	color: var(--brand-white);
	background-color: var(--brand-primary);
	border-radius: 20px;
	padding: 4px 17px 5px 17px;
}
.bubble-detail {
	display: flex;
}
.bubble-detail p {
	font-size: 12px;
	margin: 0px;
	color: var(--brand-medium-gray);
}
.bubble-detail .decoration {
	width: 10px;
	border-radius: 5px;
	background-color: var(--brand-primary);
	margin-right: 10px;
}
.item-collection {
	padding: 15px 0px 80px 0px;
	margin-left: -10px;
}
.user-card {
	width: 220px;
	float: left;
	margin: 12px 12px 12px 12px;
	background-color: var(--brand-white);
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	cursor: pointer;
	padding: 12px 12px 18px 12px;
	text-align: center;
	position: relative;
	z-index: 1 !important;
}
.org-card {
	width: 220px;
	float: left;
	margin: 12px 12px 12px 12px;
	background-color: var(--brand-white);
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	cursor: pointer;
	padding: 12px 12px 18px 12px;
	text-align: center;
	position: relative;
	z-index: 1 !important;
}
.user-card .profile-image-icon,
.org-card .profile-image-icon,
.profile-card .profile-image-icon {
	border-radius: 50%;
	height: 72px;
	width: 72px;
	margin: 8px auto 0px;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: var(--brand-lighter-gray);
}
.org-card .profile-image-icon {
	border-radius: 10px;
}
.user-card .profile-image-icon h3,
.org-card .profile-image-icon h3,
.profile-card .profile-image-icon h3 {
	font-size: 2.4rem;
	color: var(--brand-primary);
	font-weight: 700;
	padding-top: 9px;
	margin: 0px;
}
.user-card .name-section,
.org-card .name-section,
.profile-card .name-section {
	margin-top: 10px;
}
.user-card .name-section h2,
.profile-card .name-section h2 {
	font-size: 1.3rem;
	font-weight: 600;
	margin-bottom: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.org-card .name-section h2 {
	font-size: 1.3rem;
	font-weight: 600;
	max-height: 58px;
	margin-bottom: 8px;
	overflow: hidden;
}
.user-card .name-section h3,
.org-card .name-section h3,
.profile-card .name-section h3 {
	font-size: 0.9rem;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px 0px 8px 0px;
	color: var(--brand-dark-gray);
}
.org-card .name-section h3 {
	margin: 0px;
}
.user-card .school-section,
.profile-card .school-section {
	margin-top: 20px;
}
.user-card .school-section h3,
.profile-card .school-section h3 {
	font-size: 0.9rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
	margin-bottom: 2px;
	color: var(--brand-dark-gray);
}
.user-card .school-section h4,
.profile-card .school-section h4 {
	font-size: 0.9rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--brand-dark-gray);
	margin: 0px;
	height: 20px;
}
.user-card .role-section,
.profile-card .role-section {
	margin-top: 16px;
}
.user-card .card-selector,
.org-card .card-selector {
	width: 20px;
	height: 20px;
	border: 1px solid var(--brand-medium-light-gray);
	position: absolute;
	top: 14px;
	left: 14px;
	border-radius: 50%;
}
.row-selector {
	width: 20px;
	height: 20px;
	border: 1px solid var(--brand-medium-light-gray);
	border-radius: 50%;
}
.role-section .role-badge {
	background-color: var(--brand-yellow);
	padding: 3px 11px 0px 11px;
	height: 26px;
	border-radius: 15px;
	font-weight: 600;
	font-size: 12.5px;
	color: var(--brand-white);
	margin: 0px 2px 0px 2px;
	display: inline-block;
}
.title-holder button,
.small-button {
	font-weight: 500;
	font-size: 14.5px;
	background-color: transparent;
	border: 1px solid var(--brand-dark-gray);
	color: var(--brand-dark-gray);
	border-radius: 4px;
	height: 40px;
	padding: 9px 0px 11px 0px;
	cursor: pointer;
}
.title-holder button:focus,
.small-button:focus {
	background-color: transparent !important;
	border: 1px solid var(--brand-dark-gray) !important;
	color: var(--brand-dark-gray) !important;
	opacity: 1 !important;
	outline: 0;
}
.title-holder button:hover,
.title-holder button:disabled,
.small-button:hover,
.small-button:disabled {
	background-color: transparent !important;
	border: 1px solid var(--brand-dark-gray) !important;
	color: var(--brand-dark-gray) !important;
	opacity: 0.7 !important;
	outline: 0;
}
.title-holder .check-out-button,
.title-holder .check-out-button:hover,
.title-holder .check-out-button:active,
.title-holder .check-out-button:focus,
.title-holder .check-out-button:disabled {
	width: 160px;
	float: right;
	margin-top: 2px;
	margin-right: 10px;
	background-color: var(--brand-primary) !important;
	border: 1px solid var(--brand-primary) !important;
	color: var(--brand-white) !important;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14) !important;
}
.card-content {
	background-color: var(--brand-white);
	padding: 0px 0px 0px 0px;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 50px;
	min-height: 80px;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	overflow: hidden;
}
.card-content .table-container {
	overflow-y: hidden;
	overflow-x: scroll;
}
.card-content .table-container.grab * {
	cursor: grab;
}
.card-content .table-container::-webkit-scrollbar,
.card-content table thead::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
	height: 7px;
}
.card-content .table-container::-webkit-scrollbar-thumb,
.card-content table thead::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.card-content .table-container.flipped,
.card-content .table-container.flipped .flipped {
	transform: rotateX(180deg);
	-ms-transform: rotateX(180deg); /* IE 9 */
	-webkit-transform: rotateX(180deg); /* Safari and Chrome */
}
.card-content button {
	font-weight: 500;
	font-size: 14px;
	background-color: var(--brand-white);
	border: 1px solid var(--brand-card-dark-grey);
	color: var(--brand-card-dark-grey);
	border-radius: 4px;
	text-transform: uppercase;
	letter-spacing: 1px;
	height: 45px;
	padding-top: 0px;
	cursor: pointer;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.card-content button:focus {
	background-color: var(--brand-white) !important;
	border: 1px solid var(--brand-card-dark-grey) !important;
	color: var(--brand-card-dark-grey) !important;
	opacity: 1 !important;
}
.card-content button:hover,
.card-content button:disabled {
	background-color: var(--brand-white) !important;
	border: 1px solid var(--brand-card-dark-grey) !important;
	color: var(--brand-card-dark-grey) !important;
	opacity: 0.7 !important;
}
.card-content .profile-image-icon {
	border-radius: 50%;
	height: 80px;
	width: 80px;
	margin: 0px auto;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.card-content .profile-image-icon h3 {
	font-size: 3rem;
	color: var(--brand-primary);
	font-weight: 700;
	padding-top: 2px;
}
.card-content h2 {
	color: var(--brand-card-dark-grey);
	font-size: 1.15rem;
	font-weight: 400;
}
.card-content h3 {
	color: var(--brand-card-dark-grey);
	font-weight: 500;
	margin-top: 3px;
	font-size: 0.62rem;
	letter-spacing: 1.2px;
}
.card-content a {
	color: var(--brand-text-light-gray);
	font-weight: 500;
	font-size: 0.75rem;
	display: block;
}
.card-content a:hover {
	text-decoration: underline;
}
.search-field {
	padding: 0px 18px 0px 18px !important;
	text-align: left !important;
}
.page-content {
	max-width: 480px;
	width: 100%;
	margin: 40px auto 50px;
}
.page-content-large {
	max-width: 580px;
	width: 90%;
	margin: 40px auto 50px;
}
.page-content h2,
.page-content-large h2 {
	font-weight: 700;
	font-size: 1.8rem;
}
.page-content a,
.page-content-large a {
	color: var(--brand-primary);
	font-weight: 600;
	font-size: 15px;
}
.page-content a:hover,
.page-content-large a:hover {
	text-decoration: underline;
}
.asset-card {
	background-color: var(--brand-white);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	border-radius: 4px;
	margin-bottom: 20px;
	padding-left: 17px;
	padding-right: 17px;
	padding-top: 10px;
	padding-bottom: 4px;
	cursor: pointer;
	z-index: 5;
	position: relative;
}
.asset-card-inner {
	overflow: hidden;
}
.asset-card p {
	margin: 0px;
	font-size: 11.5px;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--brand-medium-gray);
}
.asset-card h3 {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	font-size: 1.03rem;
	margin-bottom: 9px;
	font-weight: 500;
	height: 23px;
}
.type-holder,
.asset-user-holder {
	background-color: var(--brand-primary);
	border-radius: 20px;
	padding: 4px 17px 5px 17px;
	margin-bottom: 10px;
	display: inline-block;
}
.type-holder p,
.asset-user-holder p {
	color: var(--brand-white);
	margin: 0px;
	font-size: 13px;
	font-weight: 600;
}
.asset-user-holder {
	margin-left: 5px;
}
.action-holder {
	padding: 4px 0px 5px 12px;
	float: right;
	margin-bottom: 10px;
	margin-left: -120px;
	cursor: pointer;
	position: relative;
	display: flex;
	z-index: 4;
	background-color: var(--brand-nav-bg);
}
.action-holder p {
	margin: 0px;
	font-size: 14px;
	font-weight: 600;
	color: var(--brand-medium-gray);
}
.asset-holder {
	margin-top: 30px;
}
.request-action-container {
	display: flex;
	margin: -5px 0px 40px;
}
.request-action-container button {
	height: 34px;
	border-radius: 4px;
	color: var(--brand-white);
	font-size: 13px;
	font-weight: 600;
	cursor: pointer;
	flex-grow: 1;
}
.request-action-container button:hover {
	opacity: 0.7;
}
.request-action-container button.positive {
	background-color: var(--brand-primary);
	border: 1px solid var(--brand-primary);
	margin-left: 5px;
}
.request-action-container button.negative {
	background-color: var(--brand-nav-bg);
	border: 2px solid var(--brand-orange);
	color: var(--brand-orange);
	margin-right: 5px;
}
.page-button {
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	border-radius: 4px;
	background-color: var(--brand-primary);
	width: 85%;
	height: 57px;
	padding: 0px 16px 0px 16px;
	color: var(--brand-white);
	margin: 40px auto 0px;
	display: block;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	opacity: 1;
}
.remove-button {
	width: 150px;
	bottom: 30px;
	right: 40px;
	position: fixed;
	z-index: 99;
	display: none;
	-webkit-transition-duration: 0s !important;
	transition-duration: 0s !important;
}
.back-to-search-button {
	width: 85%;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	margin: 30px auto 0px;
	display: block;
	font-weight: 600;
	cursor: pointer;
	color: var(--brand-medium-gray);
	font-size: 14.5px;
}
.back-button-holder h3 {
	display: block;
	font-weight: 600;
	cursor: pointer;
	color: var(--brand-medium-gray);
	font-size: 14.5px;
}
.action-holder p:hover {
	text-decoration: underline;
}
.guess-holder {
	margin-top: 40px;
}
.form-holder {
	margin-top: 40px;
}
.login-spacer {
	padding-top: 50px;
}
.asset-image-holder {
	height: 170px;
	display: block;
	margin: 20px auto 20px;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.loading-indicator-holder {
	padding: 150px 0px 240px 0px;
}
.loader {
	font-size: 10px;
	margin: 0px auto;
	text-indent: -9999em;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--brand-white-text);
	background: -moz-linear-gradient(left, var(--brand-white-text) 50%, rgba(255, 255, 255, 0) 50%);
	background: -webkit-linear-gradient(left, var(--brand-white-text) 50%, rgba(255, 255, 255, 0) 50%);
	background: -o-linear-gradient(left, var(--brand-white-text) 50%, rgba(255, 255, 255, 0) 50%);
	background: -ms-linear-gradient(left, var(--brand-white-text) 50%, rgba(255, 255, 255, 0) 50%);
	background: linear-gradient(to right, var(--brand-white-text) 50%, rgba(255, 255, 255, 0) 50%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader-blue {
	font-size: 10px;
	margin: 0px auto;
	text-indent: -9999em;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--brand-white-text);
	background: -moz-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader-page {
	font-size: 10px;
	margin: 0px auto;
	text-indent: -9999em;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: var(--brand-primary);
	background: -moz-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, var(--brand-primary) 50%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.blue-loader-page {
	font-size: 10px;
	margin: 0px auto;
	text-indent: -9999em;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: var(--brand-white);
	background: -moz-linear-gradient(left, var(--brand-white) 50%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, var(--brand-white) 50%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, var(--brand-white) 50%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, var(--brand-white) 50%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, var(--brand-white) 50%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.dark-loader {
	font-size: 10px;
	margin: 0px auto;
	text-indent: -9999em;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--brand-dark-gray);
	background: -moz-linear-gradient(left, var(--brand-dark-gray) 10%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, var(--brand-dark-gray) 10%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, var(--brand-dark-gray) 10%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, var(--brand-dark-gray) 10%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, var(--brand-dark-gray) 10%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader:before {
	width: 50%;
	height: 50%;
	background: var(--brand-white-text);
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.loader:after {
	background: var(--brand-primary);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.loader-blue:before {
	width: 50%;
	height: 50%;
	background: var(--brand-primary);
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.loader-blue:after {
	background: var(--brand-white);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.loader-page:before {
	width: 50%;
	height: 50%;
	background: var(--brand-primary);
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.loader-page:after {
	background: var(--brand-nav-bg);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.blue-loader-page:before {
	width: 50%;
	height: 50%;
	background: var(--brand-white);
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.blue-loader-page:after {
	background: var(--brand-primary);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.dark-loader:before {
	width: 50%;
	height: 50%;
	background: var(--brand-dark-gray);
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.dark-loader:after {
	background: var(--brand-white);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.loader-padding {
	padding: 300px 0px 0px 0px;
}
.account-card,
.metric-card {
	background-color: var(--brand-white);
	text-align: center;
	margin: 28px 14px 0px 14px;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	overflow: hidden;
	float: left;
}
.metric-card {
	width: 100%;
	height: 400px;
	padding: 14px 18px;
	margin-top: 0px;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	margin-bottom: 20px;
}
.metric-card-header {
	margin-left: 14px;
	margin-right: 14px;
}
.metric-card h3 {
	text-align: left;
	font-size: 1.3rem;
	font-weight: 600;
}
.metric-card .empty-view,
.metric-card .table-view {
	flex-grow: 1;
	display: flex;
}
.metric-card .empty-view h3 {
	font-weight: 400;
	font-size: 1rem;
	text-align: center;
	padding: 0px 50px;
	justify-content: center;
	align-items: center;
	display: flex;
}
#metrics-container .columns:last-child:not(:first-child) {
	float: left;
}
.account-card-inner {
	padding: 20px 20px 20px 20px;
}
.account-card .page-button {
	width: 100%;
	border-radius: 0px;
	margin-top: 6px;
	height: 46px;
	font-size: 15px;
}
.account-column {
	padding: 0px 10px 0px 15px !important;
}
#profile-card {
	width: 240px;
}
.onboard-org-holder {
	width: 92%;
	margin: 0px auto;
}
.onboard-org-item {
	margin-bottom: 15px;
	height: 70px;
}
.onboard-org-item:first-child {
	margin-top: 35px;
}
.onboard-org-item-left {
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	float: left;
	width: 79%;
	height: 70px;
	padding: 11px 14px 0px 15px;
}
.onboard-org-item-right {
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	float: right;
	width: 18%;
	height: 70px;
	margin: 0px;
}
.onboard-org-item-left p {
	margin: 0px;
	font-size: 13px;
	font-weight: 600;
	white-space: nowrap;
	overflow: scroll;
	color: var(--brand-medium-gray);
}
.onboard-org-item-left p::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.onboard-org-item-left h3 {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	font-size: 1.1rem;
	margin-bottom: 3px;
	font-weight: 500;
}
.page-empty-view {
	display: none;
	padding: 130px 0px 100px;
}
.page-empty-view h2 {
	font-size: 1.45rem;
	text-align: center;
	color: var(--brand-inactive-gray);
}
.page-empty-view .page-button {
	width: auto !important;
	margin-top: 25px;
	padding-left: 25px;
	padding-right: 25px;
}
.table-overflow {
	overflow-x: scroll;
}
.asset-checked-out-row {
	display: flex;
	height: 80px;
	border-bottom: 1px solid var(--brand-medium-light-gray);
	align-items: center;
}
.row-left {
	flex-grow: 1;
}
.row-right button {
	cursor: pointer;
	text-align: center;
	background-color: var(--brand-primary);
	color: var(--brand-white);
	font-weight: 600;
	height: 40px;
	width: 90px;
	border-radius: 4px;
	font-size: 15px;
}
.check-out-name-detail {
	display: flex;
	align-items: center;
	margin-bottom: 2px;
}
.check-out-name-detail span {
	font-weight: 500;
}
.check-out-quantity-detail {
	color: var(--brand-white);
	background-color: var(--brand-primary);
	border-radius: 4px;
	font-size: 16px;
	width: 35px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
}
.check-out-date-detail p {
	margin: 0px;
	color: var(--brand-text-light-gray);
	font-size: 13px;
	font-weight: 600;
}
.asset-check-out-action-modal-remaining {
	background-color: var(--brand-primary);
	border-radius: 4px;
	height: 55px;
	display: flex;
	align-items: center;
	box-shadow: 0px 5px 20px rgb(0 0 0 / 14%);
}
.asset-check-out-action-modal-remaining p {
	color: var(--brand-white);
	margin: 0px 0px 0px 16px;
	font-size: 1.15em;
	font-weight: 500;
}

/* TABLE CONTENT */

.card-content table,
.table-view table {
	table-layout: fixed;
	border-collapse: collapse;
	border: none;
	border-style: hidden !important;
	margin: 0px 0px 0px 0px;
	width: 100%;
	text-align: left;
}
table thead,
table tbody {
	border: none !important;
}
.card-content table thead {
	overflow: scroll;
}
.card-content table tr:first-child th,
.table-view table tr:first-child th {
	font-weight: 700;
	color: var(--brand-white);
	cursor: pointer;
	width: 200px;
	background-color: var(--brand-primary);
}
.card-content table.plain-table tr:first-child th,
.table-view table.plain-table tr:first-child th {
	font-weight: 700;
	color: var(--brand-dark-gray);
	cursor: pointer;
	width: 200px;
	background-color: var(--brand-white);
}
.card-content table tr:first-child th:first-child,
.table-view table tr:first-child th:first-child {
	border-top-left-radius: 4px;
}
.card-content table tr:first-child th:last-child,
.table-view table tr:first-child th:last-child {
	border-top-right-radius: 4px;
}
.card-content table tr:last-child td:first-child,
.table-view table tr:last-child td:first-child {
	border-bottom-left-radius: 4px;
}
.card-content table tr:last-child td:last-child,
.table-view table tr:last-child td:last-child {
	border-bottom-right-radius: 4px;
}
.card-content table tr,
.table-view table tr {
	font-weight: 500;
	color: var(--brand-dark-gray);
	cursor: pointer;
}
.card-content table td,
.table-view table td {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 200px;
}
table tbody tr {
	background-color: var(--brand-white) !important;
}
.table-empty-state-text {
	padding-left: 21px;
	text-align: left;
	font-weight: 500 !important;
	font-size: 1rem !important;
	color: var(--brand-inactive-gray) !important;
	margin: 30px 0px 30px 0px;
}
.table-container td span.emphasis {
	border-radius: 3px;
	margin: 0px 4px 0px 0px;
	color: var(--brand-white);
	padding: 4px 6px 4px 6px;
	font-size: 0.75rem;
	margin-left: 8px;
	position: relative;
	top: -1px;
}
.table-container td span.emphasis.red {
	background-color: var(--brand-orange);
}
.table-container td span.emphasis.yellow {
	background-color: var(--brand-yellow);
}
.table-container td span.emphasis.green {
	background-color: var(--brand-green);
}
.asset-row-even {
	background-color: var(--brand-white) !important;
}
.asset-row-odd {
	background-color: var(--brand-table-row) !important;
}
.card-content table .asset-group-container {
	border-style: none !important;
}
.card-content table .asset-group-container tr {
	border-top: 1px solid var(--brand-medium-light-gray) !important;
}
.card-content table .asset-group-container tr td {
	padding-top: 12px;
	padding-bottom: 14px;
}
.asset-status-indicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 8px;
	display: inline-block;
	position: relative;
	top: -1px;
}

/* CONTENT */

.faq-section video {
	width: 98%;
	border-radius: 6px;
	margin: 15px 0px 0px;
}
.team-bio {
	text-align: center;
	margin-top: 40px;
}
.team-bio h3 {
	font-weight: 700;
	font-size: 1.1rem;
	margin-bottom: 5px;
}
.team-bio h4 {
	font-weight: 500;
	font-size: 0.9rem;
	opacity: 0.6;
}
.team-bio-image {
	width: 90px;
	height: 90px;
	border-radius: 50%;
	margin-bottom: 17px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.lower-cta-section {
	background-color: var(--brand-primary);
	text-align: center;
}
.lower-cta-section button {
	margin-top: 15px;
}
.testimonial-section {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 20px 4px 0px 4px;
	text-align: center;
}
.testimonial-section p {
	color: var(--brand-primary);
	font-weight: 500;
	font-size: 1.3rem;
}
.testimonial-section h4 {
	font-weight: 600;
	font-size: 0.9rem;
	opacity: 0.5;
}
.testimonial-section .testimonial-image {
	width: 85px;
	height: 85px;
	border-radius: 50%;
	margin-top: 22px;
	margin-bottom: 17px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.testimonal-quote {
	width: 40px;
	height: 40px;
	margin-bottom: 22px;
	opacity: 0.8;
}
.sub-section h2 {
	color: var(--brand-dark-gray);
	font-size: 1.8rem;
}
.reasons-section h2 {
	font-size: 1.8rem;
	text-align: center;
	font-weight: 700;
	display: inline-block;
	padding: 18px 24px 18px 24px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	background-color: var(--brand-yellow);
	border-radius: 6px;
	color: var(--brand-dark-gray);
}
.reasons-section .role-image {
	margin-bottom: -20px;
	background-color: transparent;
	box-shadow: none;
}
.reason-card {
	text-align: center;
	margin-top: 30px;
}
.reason-card-inner {
	margin: 0px auto;
	width: 86%;
}
.reason-card h3 {
	font-weight: 700;
	font-size: 1.8rem;
	margin: 23px 0px 18px 0px;
}
.sub-section h3 {
	color: var(--brand-dark-gray);
	font-size: 1.2rem;
	line-height: 1.7;
}
.highlight-span {
	background-color: var(--brand-yellow);
	padding: 2px 7px 2px 7px;
	border-radius: 3px;
	color: var(--brand-dark-gray);
}
@media only screen {
	#video-container {
		width: 90% !important;
	}
}
@media only screen and (min-width: 45.0625em) {
	#video-container {
		width: 600px !important;
	}
}
#video-container {
	margin: auto;
	display: block;
	border-radius: 6px;
	padding: 0px 0px 0px 0px;
	background-color: var(--brand-white);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.24);
}
.videoContainer {
	position: relative;
}
.videoContainer h2 {
	margin-bottom: 45px;
	color: var(--brand-white);
	font-size: 2rem;
	text-align: center;
	font-weight: 700;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}
#videoElement {
	display: block;
	border-radius: 5px;
	width: 100% !important;
}
.listenButtonContainer {
	position: absolute;
	left: 50%;
	bottom: 25px;
}
.listenButton {
	z-index: 98;
	cursor: pointer;
	position: relative;
	left: -50%;
	text-align: center;
	background-color: var(--brand-primary);
	padding: 8px 40px 0px 40px;
	height: 46px;
	border-radius: 23px;
	-webkit-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
	width: 190px;
}
.listenButton h4 {
	color: var(--brand-white);
	font-size: 1rem;
	font-weight: 700;
	margin-top: 3px;
}
#demo-phone {
	height: 48px;
	width: 210px;
	border: none;
	border-radius: 4px;
	display: inline-block;
	font-weight: 500;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.iti-flag {
	background-image: url(/css/3f9d4c2eaac6057ce601cdedaf27cb10.png);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
	.iti-flag {
		background-image: url(/css/1ff49fd4eddddb2bde9f78c2434bb12e.png);
	}
}
.country-list span {
	color: var(--brand-dark-gray);
}
.reason-sub-section {
	margin-top: 40px;
}
.reason-sub-section p {
	font-weight: 700;
	width: 80%;
	margin: 0rem auto 1rem;
}
.reason-sub-section h3 {
	margin: 10px 0px 10px 0px;
}
.devices-holder-hero {
	width: 829px;
	margin-left: -10px;
	top: 20px;
	-webkit-transform: rotate(-4deg);
	transform: rotate(-4deg);
	transform-origin: 100% 0;
	flex-wrap: wrap;
	pointer-events: none;
	position: absolute;
	display: flex;
}
.devices-holder-demo {
	width: 950px;
	margin-left: -10px;
	top: -30px;
	-webkit-transform: rotate(-4deg);
	transform: rotate(-4deg);
	transform-origin: 100% 0;
	flex-wrap: wrap;
	pointer-events: none;
	position: absolute;
	display: flex;
}
@media only screen {
	.devices-holder-hero {
		display: none;
	}
	.devices-holder-demo {
		display: none;
	}
}
@media only screen and (min-width: 40em) {
	.devices-holder-hero {
		left: 73%;
		display: flex;
	}
	.devices-holder-demo {
		left: 73%;
		display: flex;
	}
}
@media only screen and (min-width: 64.063em) {
	.devices-holder-hero {
		left: 60%;
		display: flex;
	}
	.devices-holder-demo {
		left: 60%;
		display: flex;
	}
}
.devices-holder-hero div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 38px;
	background: var(--brand-white-text);
	-webkit-box-shadow: inset 0 4px 7px 1px var(--brand-white-text), inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08);
	box-shadow: inset 0 4px 7px 1px var(--brand-white-text), inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08);
}
.devices-holder-demo div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 38px;
	background: var(--brand-white-text);
	-webkit-box-shadow: inset 0 4px 7px 1px var(--brand-white-text), inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08);
	box-shadow: inset 0 4px 7px 1px var(--brand-white-text), inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08);
}
.tablet-landscape {
	align-self: flex-end;
	margin-right: 50px;
	width: 512px;
	height: 352px;
}
.phone-large {
	display: flex;
	width: 267px;
	height: 553px;
}
.phone-small {
	order: 0;
	margin: 50px 50px 0 0;
	width: 225px;
	height: 467px;
}
.tablet-portrait {
	display: flex;
	width: 450px;
	height: 675px;
	margin-top: 50px;
}
.link-hero-section a {
	color: var(--brand-white);
	font-weight: 600;
	font-size: 15px;
}
.link-hero-section a:hover {
	text-decoration: underline;
}
.link-hero-section p {
	color: var(--brand-white);
	font-weight: 600;
	display: inline-block;
	margin: 0px 6px 0px 6px;
}
.StripeElement {
	margin: 15px auto 0px;
	width: 90%;
	background-color: var(--brand-white);
	height: 67px;
	padding: 25px 18px;
	border-radius: 4px;
	border: none;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.StripeElement--invalid {
	border-color: var(--brand-orange);
}
.StripeElement--webkit-autofill {
	background-color: var(--brand-white) !important;
}
.payment-option-text {
	text-align: center;
	width: 90%;
	margin: 0px auto;
}
.payment-option-text p {
	opacity: 0.5;
	font-weight: 500;
	font-size: 0.9rem;
	padding: 0px 17px 0px 17px;
}
.payment-option-text h3 {
	color: var(--brand-white) !important;
	font-weight: 700 !important;
	font-size: 1rem !important;
}
.payment-option-text a {
	color: var(--brand-dark-gray);
	text-decoration: underline;
	font-weight: 500;
}
.invite-email-container {
	background-color: var(--brand-white);
	border: none;
	height: 230px;
	resize: vertical;
	outline: none;
	border-radius: 4px;
	padding: 18px 18px;
	overflow: scroll;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	font-size: 1.05rem;
	color: var(--brand-dark-gray);
	font-weight: 500;
	margin-top: 15px;
}
.invite-email-container strong {
	color: var(--brand-white);
	background-color: var(--brand-primary);
	border-radius: 20px;
	padding: 4px 14px 3px 14px;
	display: inline-block;
	margin-bottom: 6px;
	font-weight: 500;
}
[contenteditable='true']:empty:before {
	content: attr(placeholder);
	opacity: 0.35;
	display: block; /* For Firefox */
}
.phone-demo {
	order: 0;
	margin: 0px 50px 50px 0;
	width: 200px;
	height: 415px;
}
.phone-demo h3 {
	font-size: 1.1rem;
	color: var(--brand-dark-gray);
	font-weight: 700;
	position: absolute;
	bottom: 88px;
	opacity: 0.3;
}
.book-demo {
	order: 0;
	margin: 0px 0px 50px 0;
	width: 600px;
	height: 415px;
}
.calculator-demo {
	align-self: flex-end;
	margin-right: 50px;
	width: 460px;
	height: 210px;
}
.phone-mockup-fade-out {
	transition: opacity 0.5s ease-in-out;
	-webkit-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	-ms-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	opacity: 0;
}
.phone-mockup-fade-in {
	transition: opacity 0.5s ease-in-out;
	-webkit-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	-ms-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	opacity: 1 !important;
}
.phone-mockup {
	opacity: 0;
}
.barcode-holder {
	position: absolute;
	background-color: transparent !important;
	box-shadow: none !important;
}
.barcode-holder h3 {
	position: absolute;
	z-index: 1;
	color: var(--brand-dark-gray);
	font-weight: 700;
	font-size: 0.93rem;
	top: 0px;
	box-shadow: none;
	width: 90%;
	padding: 5px 0px 5px 0px;
	background-color: var(--brand-yellow);
	border-radius: 6px !important;
	text-align: center;
	opacity: 1 !important;
	margin: 10px auto 0px;
}
.barcode {
	background-color: var(--brand-white-gray);
	padding: 7px 12px 4px 12px;
	border-radius: 6px !important;
	z-index: 2;
}
.barcode-bounce {
	animation-name: bounceIn;
	animation-duration: 500ms;
	animation-timing-function: ease;
	animation-fill-mode: forwards;
}
.barcode-text-slide {
	transition: all 500ms;
}
@keyframes bounceIn {
	0% {
		transform: scale(1) translate3d(0, 0, 0);
	}
	50% {
		transform: scale(1.1);
	}
	80% {
		transform: scale(0.89);
	}
	100% {
		transform: scale(1) translate3d(0, 0, 0);
	}
}
.numberOfOrgsHolder {
	margin: 15px auto 0px;
	width: 90%;
	height: 67px;
	background-color: var(--brand-white);
	border: none;
	outline: none;
	border-radius: 4px;
	padding: 12px 18px 12px 18px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.numberOfOrgsHolder h3 {
	font-size: 1.15em;
	color: var(--brand-medium-light-gray);
	font-weight: 500;
	float: left;
	margin-top: 10px;
	cursor: default;
}
.numberOfOrgsHolder input {
	padding: 0px 0px 0px 0px !important;
	margin: 0px;
	width: 70px;
	height: 100% !important;
	float: right;
	box-shadow: none !important;
	background-color: var(--brand-primary) !important;
	border-radius: 4px;
	color: var(--brand-white) !important;
	text-align: center !important;
	font-weight: 600 !important;
	font-size: 1.35rem !important;
}
.numberOfOrgsHolder input[type='number']::-webkit-inner-spin-button,
.numberOfOrgsHolder input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
.agreement-container {
	margin: 15px auto 0px;
	width: 90%;
	height: 355px;
	border: none;
	outline: none;
	padding: 0px 0px;
}
.agreement-container-inner {
	height: 284px;
	border-radius: 4px;
	border: solid 6px white;
	padding: 12px 18px;
	overflow: scroll;
	background-color: var(--brand-white);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.agreement-container-inner p {
	font-size: 0.88rem;
	margin-top: 5px;
}
.agreement-container-inner h3 {
	font-size: 1.35rem;
	font-weight: 600;
	margin-bottom: 3px;
}
.agreement-container h4 {
	font-size: 0.86rem;
	font-weight: 500;
}
.checkbox-container {
	width: 90%;
	margin: 0px auto;
}
.checkbox-container h4,
.checkbox-container h4 * {
	font-size: 0.86rem;
	font-weight: 500;
}
.checkbox-container h4 a,
.checkbox-container h4 a:link,
.checkbox-container h4 a:visited {
	color: inherit;
	text-decoration: underline;
	font-weight: inherit;
}
.custom-checkbox {
	width: 28px;
	height: 28px;
	background-color: var(--brand-white);
	border-radius: 4px;
	outline: 0;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	cursor: pointer;
	border: none;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.custom-checkbox:hover {
	background-color: var(--brand-white);
	outline: 0;
	border: none;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}
.custom-checkbox:focus,
.custom-checkbox:active,
.custom-checkbox.active {
	outline: 0;
	border: none;
	background-image: url('/img/icons/checkmark-solo.png');
	background-position: center;
	background-size: cover;
	background-color: var(--brand-primary) !important;
}
.custom-checkbox.disabled {
	border: none !important;
	background-color: var(--brand-medium-light-gray) !important;
	cursor: default;
}
.options-row.top-margin {
	margin-top: 50px;
}
.options-row.bottom-margin {
	margin-bottom: 25px;
}
.options-row .custom-checkbox {
	float: left;
	margin-right: 17px;
}
.options-row.indent {
	padding-left: 30px;
}
.custom-checkbox-content {
	display: table-cell;
	text-align: left;
	position: relative;
	top: -5px;
}
.custom-checkbox-content p {
	font-size: 1.1rem;
	margin-top: 0px;
	color: var(--brand-dark-gray);
}
#subscription-card,
#configuration-card,
#scheduling-card,
#profile-card {
	width: 260px;
	padding: 18px 20px 16px;
	text-align: center;
}
.account-card .title-container {
	display: inline-block;
	width: 100%;
}
.account-card h1 {
	font-weight: 500;
	font-size: 1.4rem;
	color: var(--brand-dark-gray);
	text-align: left;
	margin-bottom: 22px;
	float: left;
}
.account-card h2 {
	font-size: 0.9rem;
	text-align: left;
	margin-bottom: 25px;
}
.account-card h3 {
	font-weight: 600;
	color: var(--brand-medium-light-gray);
	margin-bottom: 2px;
	text-align: left;
	font-size: 0.78rem;
}
.account-card p {
	color: var(--brand-dark-gray);
	text-align: left;
	font-size: 1.05rem;
	font-weight: 500;
	margin-bottom: 12px;
	height: 26px;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.account-card a {
	font-weight: 600;
	font-size: 0.9rem;
	color: var(--brand-primary);
	text-align: left;
	float: right;
	margin-top: 7px;
}
.account-card a:hover {
	text-decoration: underline;
}
.account-card .divider {
	width: 100%;
	height: 1px;
	background-color: var(--brand-medium-light-gray);
	opacity: 0.8;
}
.subscription-card {
	float: none;
	width: 100%;
	padding: 18px 20px 16px;
	text-align: center;
	margin-left: 0px;
	margin-right: 0px;
}
.subscription-card h1 {
	margin-bottom: 5px !important;
}
.subscription-details-view button:disabled {
	background-color: var(--brand-medium-gray);
	opacity: 0.8;
	cursor: initial;
}
.subscription-details-view {
	overflow: hidden;
	height: 0px;
}
.config-section-holder h2 {
	text-align: left;
	font-weight: 700;
	font-size: 1.6rem;
}
.config-section-holder p {
	text-align: left;
	font-weight: 500;
	font-size: 16px;
	opacity: 0.6;
}
.config-subsection-holder h3 {
	text-align: left;
	font-weight: 700;
	font-size: 1.2rem;
	margin-bottom: 6px;
}
.config-subsection-holder p {
	text-align: left;
	font-weight: 500;
	font-size: 14px;
	opacity: 0.6;
}
.config-section-holder {
	margin-bottom: -16px;
}
.config-subsection-holder {
	margin-top: 36px;
}
.config-section-divider {
	height: 1px;
	margin: 60px 0px 50px 0px;
	background-color: var(--brand-dark-gray);
	opacity: 0.2;
}
#config-form-holder input {
	margin: 0px;
	padding: 0px 16px;
	text-align: left;
	height: 50px;
	font-size: 1.1rem;
	width: 85%;
}
.config-parameter-container {
	margin-top: 30px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	border-radius: 5px;
	background-color: var(--brand-white);
	padding: 6px;
}
.config-field-holder {
	min-height: 250px;
	padding: 0px 0px 0px 0px;
	height: auto;
	background-color: var(--brand-lighter-gray);
}
.config-field-holder.expanded {
	min-height: 350px;
}
.config-field-holder.row-section {
	background-color: var(--brand-lighter-gray);
	overflow: scroll;
	border-right: 4px white solid;
}
.config-field-holder.row-section.left {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.config-field-row {
	height: 50px;
	width: 100%;
	background-color: var(--brand-lighter-gray);
	cursor: pointer;
	position: relative;
	z-index: 1;
}
.config-field-row-inner {
	padding: 11px 36px 0px 16px;
	height: 100%;
	transition: 0.2s ease-in-out;
	-webkit-transition: 0.2s ease-in-out;
	-moz-transition: 0.2s ease-in-out;
	-ms-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
}
.config-field-row:not(.selected) .config-field-row-inner:hover {
	background-color: var(--brand-light-gray);
}
.config-field-row p {
	margin: 0px 0px 0px;
	font-weight: 500;
	opacity: 0.5;
	font-size: 1.05rem;
	display: inline-block;
	cursor: text;
	white-space: nowrap !important;
	overflow: hidden !important;
	max-width: 100%;
}
.config-field-row [contenteditable]:focus {
	outline: 0px solid transparent;
}
.config-field-row.selected {
	background-color: var(--brand-white);
	box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.07);
	cursor: move;
	position: relative;
	z-index: 2;
}
.config-field-row.selected p {
	opacity: 1;
	font-weight: 600;
}
.configuraton-helper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 75%;
	margin: 0px auto;
}
.configuraton-helper p {
	text-align: center;
	font-weight: 500;
	opacity: 0.5;
	font-size: 0.9rem;
}
.configuration-options {
	width: 100%;
	height: 100%;
	background-color: var(--brand-lighter-gray);
	padding-bottom: 20px;
}
.configuration-options .options-holder {
	width: 100%;
	height: 100%;
	max-height: 590px;
	overflow: scroll;
}
.configuration-options .options-row {
	background-color: var(--brand-lighter-gray);
	padding: 9px 12px 0px 16px;
}
.options-title-row {
	height: 50px;
	padding: 13px 16px 0px 16px;
}
.options-title-row span {
	opacity: 0.4;
	margin-left: 3px;
	font-size: 0.95rem;
}
.configuration-options h3,
.options-title-row h3 {
	font-size: 1.05rem;
	font-weight: 600;
}
.options-dropdown-section {
	padding: 9px 16px 0px 16px !important;
	margin-bottom: 16px !important;
}
.options-dropdown-section .config-parameter-container {
	margin-top: 10px !important;
}
.options-dropdown-section .config-parameter-container .config-field-row {
	cursor: move !important;
	height: 42px !important;
}
.options-dropdown-section .config-field-row-inner {
	padding-top: 7px !important;
}
.options-dropdown-section .remove-icon {
	top: 13px !important;
}
.options-dropdown-section .config-parameter-container p {
	font-size: 14.5px !important;
	margin-top: 2px !important;
}
.configuration-options .custom-checkbox {
	width: 20px;
	height: 20px;
	float: left;
	margin: 7px 12px 0px 0px;
}
.configuration-options .custom-checkbox.inactive {
	background-color: var(--brand-dark-gray) !important;
	opacity: 0.3;
	pointer-events: none;
}
.configuration-options .custom-checkbox-text {
	display: table-cell;
	text-align: left;
	padding-top: 3px;
}
.configuration-options .custom-checkbox-text h4 {
	font-weight: 600;
	font-size: 15.5px;
	margin: 2px 0px 0px;
}
.configuration-options .options-row p {
	font-weight: 500;
	font-size: 13.5px;
	opacity: 0.6;
	margin-top: 11px;
}
.config-field-row .remove-icon {
	height: 16px;
	width: 16px;
	background-image: url('/img/icons/remove.png');
	background-position: center;
	background-size: cover;
	opacity: 0;
	position: absolute;
	top: 17px;
	right: 13px;
	transition: 0.2s ease-in-out;
	-webkit-transition: 0.2s ease-in-out;
	-moz-transition: 0.2s ease-in-out;
	-ms-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
	z-index: 2;
	cursor: pointer;
}
.config-field-row:hover .remove-icon {
	opacity: 0.5;
}
.config-field-row.selected:hover .remove-icon {
	opacity: 1;
}
.config-field-row .restricted-icon {
	height: 11px;
	width: 11px;
	background-image: url('/img/icons/restricted.png');
	background-position: center;
	background-size: cover;
	opacity: 0.5;
	position: absolute;
	top: 19.5px;
	right: 15px;
	transition: 0.2s ease-in-out;
	-webkit-transition: 0.2s ease-in-out;
	-moz-transition: 0.2s ease-in-out;
	-ms-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
	z-index: 1;
}
.config-field-row.selected .restricted-icon {
	opacity: 1;
}
.config-field-row:hover .restricted-icon {
	opacity: 0;
}
.config-field-row .restricted-icon.static {
	opacity: 0.5;
}
.config-field-row.selected .restricted-icon.static {
	opacity: 1;
}
.options-row .select-input {
	color: var(--brand-dark-gray);
	font-weight: 600;
	font-size: 15.5px;
	margin: 4px 0px 0px 0px;
	float: left;
	width: 100px !important;
	border: none !important;
	background-color: var(--brand-white);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	border-radius: 4px;
	padding-left: 12px;
	background-size: 13px 13px;
}
.options-row .select-input.inactive {
	color: var(--brand-inactive-gray);
	pointer-events: none;
}
.section-divider-holder {
	padding: 25px 0px;
}
.section-divider-holder h3 {
	width: 100%;
	text-align: center;
	color: var(--brand-text-light-gray);
	border-bottom: 1px solid var(--brand-text-light-gray);
	line-height: 0.1em;
	margin: 10px 0 10px;
	font-size: 0.9rem;
	font-weight: 500;
}
.section-divider-holder h3 span {
	background: var(--brand-white-gray);
	padding: 0 10px;
}

/* COOKIE BAR CONTENT */

.cookie-bar {
	height: auto;
	background-color: var(--brand-white);
	position: fixed;
	display: flex;
	justify-content: space-between;
	bottom: 0;
	width: 100%;
	z-index: 998;
	padding-top: 20px;
	padding-bottom: 20px;
	box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.06);
}
.cookie-bar .text-container {
	display: flex;
	align-items: center;
}
.cookie-bar .text-container p {
	margin: 0px;
	font-size: 0.9rem;
	color: var(--brand-medium-gray);
	font-weight: 600;
}
.cookie-bar .text-container p a,
.cookie-bar .text-container p a:link,
.cookie-bar .text-container p a:visited {
	text-decoration: underline;
	color: inherit;
	text-transform: inherit;
	font-weight: inherit;
	font-family: inherit;
}
.cookie-bar .button-container {
	display: flex;
	align-items: center;
	margin: 0px 0px 0px 30px;
}
.cookie-bar button.cookie-button {
	font-size: 15px;
	white-space: nowrap;
	opacity: 1;
	text-transform: none;
	font-weight: 600;
	color: var(--brand-medium-gray);
	cursor: pointer;
}
.cookie-bar button.cookie-button.solid {
	background-color: var(--brand-yellow);
	color: var(--brand-button-yellow-gray);
	border-radius: 3px;
	padding: 12px 25px;
	margin-right: 25px;
	float: right;
}
.cookie-bar button.cookie-button:hover {
	opacity: 0.8;
}
.cookie-bar .small-screen-span {
	display: inline;
}
@media only screen and (max-width: 25em) {
	.cookie-bar .small-screen-span {
		display: none;
	}
}
@media only screen and (max-width: 45em) {
	.cookie-bar {
		display: block;
	}
	.cookie-bar .button-container {
		margin: 20px 0px 0px 0px;
	}
}

/* VOTE CTA CONTENT */

.vote-cta {
	display: flex;
	justify-content: space-between;
	padding-top: 22px;
	padding-bottom: 22px;
	background-color: var(--brand-white);
}
.vote-cta .text-container {
	display: flex;
	align-items: center;
}
.vote-cta .text-container p {
	margin: 0px;
	font-size: 0.9rem;
	color: var(--brand-medium-gray);
	font-weight: 600;
}
.vote-cta .text-container p a,
.vote-cta .text-container p a:link,
.vote-cta .text-container p a:visited {
	text-decoration: underline;
	color: inherit;
	text-transform: inherit;
	font-weight: inherit;
	font-family: inherit;
}
.vote-cta .button-container {
	display: flex;
	align-items: center;
	margin: 0px 0px 0px 40px;
}
.vote-cta button.blue-cta {
	font-size: 15px;
	white-space: nowrap;
	opacity: 1;
	padding: 0px 20px;
	text-transform: none;
	font-weight: 600;
	background-color: var(--brand-primary);
	box-shadow: none;
	color: var(--brand-white);
	cursor: pointer;
}
.vote-cta button.blue-cta:hover {
	opacity: 0.8;
}
@media only screen and (max-width: 45em) {
	.vote-cta {
		display: block;
	}
	.vote-cta .button-container {
		margin: 20px 0px 0px 0px;
	}
}

/* HEALTH CONTENT */

.health-span {
	font-weight: 600;
}
.health-span.none {
	color: var(--brand-text-light-gray);
}
.health-span.green {
	color: var(--brand-green);
}
.health-span.yellow {
	color: var(--brand-dark-yellow);
}
.health-span.red {
	color: var(--brand-orange);
}
.health-indicator {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}
.health-indicator.right {
	margin-left: 8px;
}
.health-indicator.left {
	margin-right: 8px;
}
.health-indicator.none {
	background-color: var(--brand-medium-light-gray);
}
.health-indicator.green {
	background-color: var(--brand-green);
}
.health-indicator.yellow {
	background-color: var(--brand-dark-yellow);
}
.health-indicator.red {
	background-color: var(--brand-orange);
}
.inline-current-health-status {
	margin-top: 10px;
	float: left;
	margin-left: -4px;
	margin-right: 26px;
}
.health-status-container {
	margin: -2px 0 30px;
}
.health-history-row {
	padding: 7px 0px 18px;
}
.health-history-row .columns {
	color: var(--brand-dark-gray);
	font-weight: 500 !important;
}
.featured-container {
	display: inline-block;
}
.featured-container h2 {
	color: var(--brand-white);
	font-size: 1.2rem;
	margin-bottom: 15px;
}
.featured-container a,
.featured-container a:link,
.featured-container a:visited {
	color: var(--brand-white);
}
.featured-container a:hover {
	opacity: 0.7;
}
.featured-decoration {
	height: 100px;
	width: 100px;
	background-color: var(--brand-white);
	border-radius: 50px;
	margin: 0px auto 0px;
	background-image: url('/img/keeler-mark-logo.png');
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position: center;
}
.qr-code {
	width: 18px;
	height: 18px;
	cursor: pointer;
}
.qr-print-area {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
}
.qr-print-area h3 {
	text-align: center;
	color: var(--brand-dark-gray);
	margin-top: 10px;
	font-size: 1.8rem;
}
.qr-code-container {
	text-align: center;
	border: 5px solid var(--brand-dark-gray);
	display: inline-flex;
	border-radius: 10px;
}
.qr-code-container img {
	background-color: var(--brand-white);
	border-radius: 20px;
	width: 200px;
	height: 200px;
	position: relative;
	z-index: 2;
}

/* METRICS CONTENT */

.metric-row {
	border-bottom: 1px solid var(--brand-dropdown-bg);
	margin-bottom: 20px;
}
.metric-row.clickable {
	cursor: pointer;
}
.metric-row .inner {
	padding: 0px;
	text-align: left;
}
.metric-row .top-section {
	padding: 12px 12px;
	background-color: var(--brand-dropdown-bg);
	display: flex;
	align-items: center;
	border-radius: 4px;
}
.metric-row .bottom-section {
	padding: 8px 16px 2px;
	background-color: var(--brand-white);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.metric-row .bottom-section .columns {
	padding: 0px;
}
.metric-row .inner h2 {
	text-transform: none;
	letter-spacing: normal;
	margin: 3px 0px 0px;
	font-size: 1.3rem;
	flex-grow: 1;
	font-weight: 600;
}
.metric-row .inner h2 span {
	font-size: 0.8rem !important;
	font-weight: 500;
	margin-left: 10px !important;
	display: inline-block;
}
.metric-row .inner h3 {
	text-transform: none;
	letter-spacing: normal;
	font-size: 0.8rem;
	line-height: 1.7;
	padding-right: 10px;
	font-weight: 500;
	margin-bottom: 15px;
}
.metric-row .inner .bottom-section h3 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-top: 3px;
	padding-bottom: 2px;
	position: relative;
	top: -2px;
}
.metric-row .inner h3 span.emphasis,
.review-section h3 span.emphasis {
	border-radius: 3px;
	margin: 0px 4px 0px 0px;
	color: var(--brand-white);
	padding: 4px 6px 4px 6px;
}
.metric-row .inner h3 span.emphasis.right {
	margin: 0px 0px 0px 4px;
}
.review-section h3 span.emphasis {
	padding: 5px 4px 5px 7px;
}
.metric-row .inner h3 span.emphasis.padding {
	padding-right: 6px;
}
.review-section h3 span.emphasis.padding {
	padding-right: 8px;
}
.metric-row .inner h3 span.emphasis.red,
.review-section h3 span.emphasis.red {
	background-color: var(--brand-orange);
}
.metric-row .inner h3 span.emphasis.yellow,
.review-section h3 span.emphasis.yellow {
	background-color: var(--brand-yellow);
}
.metric-row .inner h3 span.emphasis.green,
.review-section h3 span.emphasis.green {
	background-color: var(--brand-green);
}
.metric-row .inner h3 span.emphasis.outline {
	color: var(--brand-dark-gray);
	border: 1px solid var(--brand-dark-gray);
}
.review-section h3 span.emphasis.outline {
	color: var(--brand-dark-gray);
	border: 1px solid var(--brand-dark-gray);
}
.asset-group-container span.emphasis.outline {
	color: var(--brand-dark-gray);
	border: 1px solid var(--brand-dark-gray);
	margin-left: 0px;
	top: 0px !important;
}
.asset-group-container span.emphasis.outline.right {
	margin-left: 8px;
}
.top-section h2 span.emphasis.outline {
	font-size: 0.9rem;
	color: var(--brand-dark-gray);
	border: 1px solid var(--brand-dark-gray);
	border-radius: 3px;
	margin: 0px 6px 0px 0px;
	padding: 7px 7px 5px 7px;
	position: relative;
	top: -2px;
}
.top-section h2 span.emphasis.outline.multiple {
	margin: 0px;
}
.metric-row .inner h4 {
	font-size: 0.7rem;
	text-transform: uppercase;
	letter-spacing: 0.7px;
	margin: 0px 0px 5px 0px;
	font-weight: 600;
}
.top-section .number-icon {
	width: 50px;
	height: 50px;
	border-radius: 10px;
	background-color: var(--brand-white);
	text-align: center;
	margin: 0px 15px 0px 0px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
}
.metric-row .top-section .number-icon h2 {
	position: relative;
	top: 0px;
	color: var(--brand-primary);
	font-size: 1.7rem;
	font-weight: 700;
	padding-top: 0px;
	margin: 1px;
}
.metric-row .top-section button {
	height: 40px;
	padding: 5px 24px 0px 24px;
	margin-right: 15px;
}
.metric-row .top-section .active-indicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
	display: inline-block;
	position: relative;
	top: -2px;
}
.metric-row .top-section .active-indicator.active {
	background-color: var(--brand-green);
}
.metric-row .top-section .active-indicator.inactive {
	background-color: var(--brand-orange);
}
@media only screen {
	.metric-row .top-section button {
		display: none;
	}
}
@media only screen and (min-width: 30em) {
	.metric-row .top-section button {
		display: block;
	}
}
.metric-row .top-section button:hover {
	background-color: var(--brand-primary);
	color: var(--brand-white);
}
.metric-row .bottom-section .cell {
	margin-top: 12px;
}
