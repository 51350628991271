/* COPYRIGHT SMARTTRACK */

:root {
	--brand-white: white;
	--brand-primary: #22a7f0;
	--brand-primary-dark: #22a7f0;
	--brand-yellow: #f7ce27;
	--brand-light-yellow: #f7d449;
	--brand-dark-yellow: #f39c12;
	--brand-accent-yellow: #f7ce27;
	--brand-orange: #e74c3c;
	--brand-green: #33ce75;
	--brand-white-text: #fff;
	--brand-text-light-gray: #a3a4a6;
	--brand-dark-gray: #413e4d;
	--brand-medium-gray: #888;
	--brand-medium-light-gray: #c3c3c3;
	--brand-light-gray: #e7e8ec;
	--brand-lighter-gray: #edeef2;
	--brand-white-gray: #f8f9fd;
	--brand-table-border: #dbd9d2;
	--brand-inactive-gray: #91919a;
	--brand-card-dark-grey: #4a4a4a;
	--brand-button-gray: #5c5969;
	--brand-button-yellow-gray: #575342;
	--brand-placeholder-gray: #aaaaaa;
	--brand-white-bg: #ededed;
	--brand-dropdown-bg: #f1f2f6;
	--brand-nav-bg: #f8f8f8;
	--brand-nav-bar-text: #3d4041;
	--brand-field-gray: #979797;
	--brand-table-row: #f1f1f1;
}
