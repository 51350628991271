/* COPYRIGHT SMARTTRACK */

/* ELEMENTS */

@media only screen {
	/* GENERAL */

	.show-for-custom-large-only {
		display: none !important;
	}
	.show-for-custom-medium-only {
		display: none !important;
	}
	.show-for-custom-small-only {
		display: block !important;
	}
	.show-for-custom-medium-up {
		display: none !important;
	}
	.hide-for-custom-medium-up {
		display: block !important;
	}
	.show-for-custom-medium-up-2 {
		display: none !important;
	}
	.hide-for-custom-medium-up-2 {
		display: block !important;
	}
}
@media only screen and (min-width: 30.563em) {
	/* Medium and up screens */

	/* GENERAL */

	.show-for-custom-large-only {
		display: none !important;
	}
	.show-for-custom-medium-only {
		display: block !important;
	}
	.show-for-custom-small-only {
		display: none !important;
	}
	.show-for-custom-medium-up {
		display: none !important;
	}
	.hide-for-custom-medium-up {
		display: block !important;
	}
}
@media only screen and (min-width: 40.563em) {
	/* Medium-small and up screens */

	/* GENERAL */

	.show-for-custom-medium-up-2 {
		display: block !important;
	}
	.hide-for-custom-medium-up-2 {
		display: none !important;
	}
}
@media only screen and (min-width: 59.563em) {
	/* Large and up screens */

	/* GENERAL */

	.show-for-custom-large-only {
		display: block !important;
	}
	.show-for-custom-medium-only {
		display: none !important;
	}
	.show-for-custom-small-only {
		display: none !important;
	}
}
@media only screen and (min-width: 69.563em) {
	/* Large and up screens */

	/* GENERAL */

	.show-for-custom-medium-up {
		display: block !important;
	}
	.show-for-custom-medium-up.inline {
		display: inline !important;
	}
	.hide-for-custom-medium-up {
		display: none !important;
	}
}
@media only screen {
	/* Small screens */

	/* HEADER */

	.header {
		padding: 14px 35px 14px 35px;
		height: 76px;
	}
	.header-offset-inner {
		height: 76px;
	}

	/* CONTENT */

	.screen-overall-padding {
		padding-left: 25px;
		padding-right: 25px;
	}
	.left-nav-bar {
		display: none;
	}
	.left-nav-bar a {
		padding-left: 38px !important;
	}
	.left-nav-content {
		width: 100%;
	}
}
@media only screen and (min-width: 40.0625em) and (max-width: 45.0625em) {
	/* HEADER */

	.header {
		padding: 14px 3vw 14px 4.5vw;
		height: 77px;
	}
	.header-offset-inner {
		height: 77px;
	}

	/* CONTENT */

	.screen-overall-padding {
		padding-left: 4.5vw;
		padding-right: 3vw;
	}
	.left-nav-bar {
		display: block;
		width: 29%;
	}
	.left-nav-bar a {
		padding-left: 4.5vw !important;
	}
	.left-nav-content {
		width: 71%;
	}
}
@media only screen and (min-width: 45.0625em) and (max-width: 60em) {
	/* HEADER */

	.header {
		padding: 14px 3vw 14px 4.5vw;
		height: 77px;
	}
	.header-offset-inner {
		height: 77px;
	}

	/* CONTENT */

	.screen-overall-padding {
		padding-left: 4.5vw;
		padding-right: 3vw;
	}
	.left-nav-bar {
		display: block;
		width: 26%;
	}
	.left-nav-bar a {
		padding-left: 4.5vw !important;
	}
	.left-nav-content {
		width: 74%;
	}
}
@media only screen and (min-width: 60em) and (max-width: 75em) {
	/* HEADER */

	.header {
		padding: 14px 3vw 14px 4.5vw;
		height: 77px;
	}
	.header-offset-inner {
		height: 77px;
	}

	/* CONTENT */

	.screen-overall-padding {
		padding-left: 4.5vw;
		padding-right: 3vw;
	}
	.left-nav-bar {
		display: block;
		width: 21%;
	}
	.left-nav-bar a {
		padding-left: 4.5vw !important;
	}
	.left-nav-content {
		width: 79%;
	}
}
@media only screen and (min-width: 75em) {
	/* HEADER */

	.header {
		padding: 14px 3vw 14px 4.5vw;
	}
	.header-offset-inner {
		height: 77px;
	}

	/* CONTENT */

	.screen-overall-padding {
		padding-left: 4.5vw;
		padding-right: 3vw;
	}
	.left-nav-bar {
		display: block;
		width: 18%;
	}
	.left-nav-bar a {
		padding-left: 4.5vw !important;
	}
	.left-nav-content {
		width: 82%;
	}
}
@media only screen {
	/* CONTENT */

	.hero-title-section-blog {
		/* Small screens */
		width: 85%;
	}
}
@media only screen and (min-width: 40.063em) {
	/* Medium and up screens */

	/* CONTENT */

	.hero-title-section-blog {
		width: 550px;
	}
}
@media only screen {
	/* SIZES */

	.side-image-text-holder h1 {
		font-size: 2.2rem;
	}
	.side-image-text-holder h2 {
		font-size: 1rem;
	}

	/* CONTENT */

	.side-image-text-section {
		/* Small screens */
		width: 88%;
	}
	.side-image-text-left {
		display: none;
	}
	.side-image-text-right {
		width: 100%;
	}
}
@media screen and (min-width: 40em) and (max-width: 54.9375em) {
	/* SIZES */

	.side-image-text-holder h1 {
		font-size: 2.4rem;
	}
	.side-image-text-holder h2 {
		font-size: 1.1rem;
	}

	/* CONTENT */

	.side-image-text-section {
		width: 400px;
	}
	.side-image-text-left {
		display: block;
		width: 40%;
	}
	.side-image-text-right {
		width: 60%;
	}
}
@media screen and (min-width: 55em) {
	/* Large and up screens */

	/* SIZES */

	.side-image-text-holder h1 {
		font-size: 2.7rem;
	}
	.side-image-text-holder h2 {
		font-size: 1.15rem;
	}

	/* CONTENT */

	.side-image-text-section {
		width: 660px;
	}
	.side-image-text-left {
		display: block;
		width: 30%;
	}
	.side-image-text-right {
		width: 70%;
	}
}
@media only screen {
	/* CONTENT */

	.hero-title-section {
		/* Small screens */
		width: 85%;
	}
	.split-form-inner,
	.split-form-inner-wide {
		width: 75%;
	}
	.split-form-text {
		width: 75%;
	}
}
@media only screen and (min-width: 40.063em) {
	/* Medium screens */

	/* CONTENT */

	.hero-title-section {
		width: 530px;
	}
}
@media only screen and (min-width: 29.5em) {
	/* Medium screens */

	/* CONTENT */

	.split-form-inner {
		width: 350px;
	}
	.split-form-inner-wide {
		width: 450px;
	}
}
@media only screen and (min-width: 35.5em) {
	/* Medium screens */

	/* CONTENT */

	.split-form-text {
		width: 425px;
	}
}
